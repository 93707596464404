let initState = {
    categoriesList : [
        {
            id: 0,
            en: "All",
            ru: "Все",
            pt: "Todos",
            es: "Todos",
            ar: "كل شيء",
        },
        {
            id: 1,
            en: "News",
            ru: "Новости",
            pt: "Notícias",
            es: "Noticias",
            ar: "أخبار",
        },
        {
            id: 2,
            en: "Blogs",
            ru: "Блоги",
            pt: "Blogues",
            es: "Blogs",
            ar: "المدونات",
        },
        {
            id: 3,
            en: "Crypto",
            ru: "Криптовалюта",
            pt: "Criptomoeda",
            es: "Criptomoneda",
            ar: "العملات الرقمية",
        },
        {
            id: 4,
            en: "Politics",
            ru: "Политика",
            pt: "Política",
            es: "Política",
            ar: "السياسة",
        },
        {
            id: 5,
            en: "Economy",
            ru: "Экономика",
            pt: "Economia",
            es: "Economía",
            ar: "الاقتصاد",
        },
        {
            id: 6,
            en: "Education",
            ru: "Образование",
            pt: "Educação",
            es: "Educación",
            ar: "التعليم",
        },
        {
            id: 7,
            en: "Journeys",
            ru: "Путешествия",
            pt: "Viajar",
            es: "Viajar",
            ar: "السفر",
        },
        {
            id: 8,
            en: "Business",
            ru: "Бизнес",
            pt: "Negócios",
            es: "Empresas",
            ar: "الأعمال",
        },
        {
            id: 9,
            en: "Games",
            ru: "Игры",
            pt: "Jogos",
            es: "Juegos",
            ar: "ألعاب",
        },
    ],
    channelList: [
        {
            id: 1,
            category: 1,
            name: 'Код Дурова',
            description: 'Новости из мира IT и Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch1.jpg',
            link: 'https://t.me/d_code',
            language: 'ru-RU'
        },
        {
            id: 2,
            category: 3,
            name: 'TON Community RUS',
            description: 'TON – это блокчейн, спроектированный Telegram и поддерживаемый открытым сообществом. В TON доступны сверхбыстрые транзакции, низкие комиссии и удобные приложения, некоторые из которых можно найти непосредственно в Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch2.jpg',
            link: 'https://t.me/toncoin_rus',
            language: 'ru-RU'
        },
        {
            id: 3,
            category: 3,
            name: 'Новости кошелька',
            description: 'Удобный и безопасный сервис для покупки, хранения и управления криптовалютой.',
            verify: null,
            avatar: 'https://897821.selcdn.ru/channels/ch3.jpg',
            link: 'https://t.me/wallet_news_cis',
            language: 'ru-RU'
        },
        {
            id: 4,
            category: 3,
            name: 'Новости Crypto Bot',
            description: 'Используйте CryptoBot для покупки, продажи, хранения и оплаты криптовалютой прямо в Telegram.',
            verify: null,
            avatar: 'https://897821.selcdn.ru/channels/ch4.jpg',
            link: 'https://t.me/CryptoBotRU',
            language: 'ru-RU'
        },
        {
            id: 5,
            category: 3,
            name: 'Getgems NFT RU',
            description: 'getgems.io — дом NFT на The Open Network (TON). TON – это блокчейн, спроектированный Telegram и поддерживаемый открытым сообществом',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch6.jpg',
            link: 'https://t.me/getgemsrus',
            language: 'ru-RU'
        },
        {
            id: 6,
            category: 3,
            name: 'Новости Тонкипера',
            description: 'Канал Тонкипера по-русски.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch7.jpg',
            link: 'https://t.me/tonkeeper_ru',
            language: 'ru-RU'
        },
        {
            id: 7,
            category: 3,
            name: 'Telegram Usernames',
            description: 'This channel is managed by the Telegram team to inform users about updates related to auctions for usernames and other items on the Telegram platform.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch8.jpg',
            link: 'https://t.me/username',
            language: 'en-EN'
        },
        {
            id: 8,
            category: 3,
            name: 'РБК-Крипто',
            description: 'Поможем разобраться в мире криптовалют и блокчейна; расскажем, как применить свои знания на практике.',
            verify: null,
            avatar: 'https://897821.selcdn.ru/channels/ch9.jpg',
            link: 'https://t.me/RBCCrypto',
            language: 'ru-RU'
        },
        {
            id: 9,
            category: 3,
            name: 'Binance Новости',
            description: 'Официальный канал Binance',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch5.jpg',
            link: 'https://t.me/binance_ru',
            language: 'ru-RU'
        },
        {
            id: 10,
            category: 1,
            name: 'РИА Новости',
            description: 'Авторы канала - наши корреспонденты в России и за рубежом. Мы рассказываем о том, что видим сами.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch10.jpg',
            link: 'https://t.me/rian_ru',
            language: 'ru-RU'
        },
        {
            id: 11,
            category: 1,
            name: 'НЕ МОРГЕНШТЕРН',
            description: 'Новостное СМИ.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch11.jpg',
            link: 'https://t.me/nemorgenshtern',
            language: 'ru-RU'
        },
        {
            id: 12,
            category: 1,
            name: 'Новости Москвы',
            description: 'Самый большой канал о Москве.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch12.jpg',
            link: 'https://t.me/moscowmap',
            language: 'ru-RU'
        },
        {
            id: 13,
            category: 2,
            name: 'Кровавая барыня',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch13.jpg',
            link: 'https://t.me/bloodysx',
            language: 'ru-RU'
        },
        {
            id: 14,
            category: 1,
            name: 'Медуза — LIVE',
            description: 'Главный телеграм-канал «Медузы».',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch14.jpg',
            link: 'https://t.me/meduzalive',
            language: 'ru-RU'
        },
        {
            id: 15,
            category: 1,
            name: 'Readovka',
            description: 'рИдовка - реальные новости',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch15.jpg',
            link: 'https://t.me/readovkanews',
            language: 'ru-RU'
        },
        {
            id: 16,
            category: 1,
            name: 'Осторожно, новости',
            description: 'Новостное СМИ.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch16.jpg',
            link: 'https://t.me/ostorozhno_novosti',
            language: 'ru-RU'
        },
        {
            id: 17,
            category: 1,
            name: 'Varlamov News',
            description: 'Новостной канал',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch17.jpg',
            link: 'https://t.me/varlamov_news',
            language: 'ru-RU'
        },
        {
            id: 18,
            category: 1,
            name: 'Mash',
            description: 'Новостное СМИ.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch18.jpg',
            link: 'https://t.me/breakingmash',
            language: 'ru-RU'
        },
        {
            id: 19,
            category: 2,
            name: 'ЛИТВИН',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch19.jpg',
            link: 'https://t.me/litvintm',
            language: 'ru-RU'
        },
        {
            id: 20,
            category: 1,
            name: 'ВПШ',
            description: 'Новостное СМИ.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch20.jpg',
            link: 'https://t.me/pravdadirty',
            language: 'ru-RU'
        },
        {
            id: 21,
            category: 2,
            name: 'Дима Масленников Блоггер',
            description: 'Это официальный канал блогера Дмитрия Масленникова',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch21.jpg',
            link: 'https://t.me/maslennikovliga',
            language: 'ru-RU'
        },
        {
            id: 22,
            category: 4,
            name: 'Дмитрий Медведев',
            description: 'Политика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch22.jpg',
            link: 'https://t.me/medvedev_telegram',
            language: 'ru-RU'
        },
        {
            id: 24,
            category: 4,
            name: 'Вячеслав Володин',
            description: 'Политика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch24.jpg',
            link: 'https://t.me/vv_volodin',
            language: 'ru-RU'
        },
        {
            id: 25,
            category: 8,
            name: 'Книги на миллион',
            description: 'Бизнес блог #1 Выжимаю книги до самой сути.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch25.jpg',
            link: 'https://t.me/ikniga',
            language: 'ru-RU'
        },
        {
            id: 26,
            category: 2,
            name: 'НЕВЗОРОВ',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch26.jpg',
            link: 'https://t.me/nevzorovtv',
            language: 'ru-RU'
        },
        {
            id: 27,
            category: 1,
            name: 'Госуслуги',
            description: 'Официальный канал портала государственных услуг Российской Федерации.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch27.jpg',
            link: 'https://t.me/gosuslugi',
            language: 'ru-RU'
        },
        {
            id: 28,
            category: 1,
            name: 'Пивоваров (Редакция)',
            description: 'Телеграм-канал Алексея Пивоварова.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch28.jpg',
            link: 'https://t.me/redakciya_channel',
            language: 'ru-RU'
        },
        {
            id: 29,
            category: 2,
            name: 'Александр Зубарев',
            description: 'Официальный канал отца всея пельменей Не ведитесь на фейки!Мы никаких сборов денег в других каналах не ведём!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch29.jpg',
            link: 'https://t.me/zubarefff',
            language: 'ru-RU'
        },
        {
            id: 30,
            category: 2,
            name: 'Borodylia',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch30.jpg',
            link: 'https://t.me/borodylia0803',
            language: 'ru-RU'
        },
        {
            id: 31,
            category: 1,
            name: 'Москвач • Новости Москвы',
            description: 'Самый большой бренд-канал о Москве',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch31.jpg',
            link: 'https://t.me/moscowach',
            language: 'ru-RU'
        },
        {
            id: 32,
            category: 2,
            name: 'Дудь',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch32.jpg',
            link: 'https://t.me/yurydud',
            language: 'ru-RU'
        },
        
        {
            id: 39,
            category: 2,
            name: 'Тина Канделаки',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch39.jpg',
            link: 'https://t.me/tikandelaki',
            language: 'ru-RU'
        },
        {
            id: 43,
            category: 2,
            name: 'Стрелец-Молодец',
            description: 'Пропагандист здравого смысла и хорошего вкуса',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch43.jpg',
            link: 'https://t.me/strelets_molodec',
            language: 'ru-RU'
        },
        {
            id: 49,
            category: 2,
            name: 'Илья Варламов',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch49.jpg',
            link: 'https://t.me/varlamov',
            language: 'ru-RU'
        },
        {
            id: 50,
            category: 1,
            name: 'RT на русском',
            description: 'Эксклюзивные репортажи. Кадры с передовой. Самые обсуждаемые и важные события в России и мире',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch50.jpg',
            link: 'https://t.me/rt_russian',
            language: 'ru-RU'
        },
        {
            id: 52,
            category: 1,
            name: 'ТАСС',
            description: 'Официальный канал агентства ТАСС',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch52.jpg',
            link: 'https://t.me/tass_agency',
            language: 'ru-RU'
        },
        {
            id: 55,
            category: 1,
            name: 'Kotsnews',
            description: 'Военкор "Комсомольской правды" Александр Коц.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch55.jpg',
            link: 'https://t.me/sashakots',
            language: 'ru-RU'
        },
        {
            id: 56,
            category: 1,
            name: 'URA.RU',
            description: 'Главные новости политики и общества.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch56.jpg',
            link: 'https://t.me/uranews',
            language: 'ru-RU'
        },
        {
            id: 57,
            category: 1,
            name: 'РБК',
            description: 'Официальный канал медиахолдинга РБК. Самые важные новости, только проверенная информация.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch57.jpg',
            link: 'https://t.me/rbc_news',
            language: 'ru-RU'
        },
        {
            id: 60,
            category: 1,
            name: 'Комсомольская правда: KP.RU',
            description: 'Важные новости, яркие фото и видео, интересные истории.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch60.jpg',
            link: 'https://t.me/truekpru',
            language: 'ru-RU'
        },
        {
            id: 63,
            category: 1,
            name: 'Рифмы и Панчи',
            description: 'Новостное СМИ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch63.jpg',
            link: 'https://t.me/rhymestg',
            language: 'ru-RU'
        },
        {
            id: 64,
            category: 1,
            name: 'Вечерний Телеграмъ',
            description: 'Объективность, достоверность, оперативность — основа нашей редполитики. Делаем новости, которым доверяют.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch64.jpg',
            link: 'https://t.me/tele_eve',
            language: 'ru-RU'
        },
        {
            id: 65,
            category: 1,
            name: 'Телеканал Дождь',
            description: 'Единственный официальный канал Дождя в Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch65.jpg',
            link: 'https://t.me/tvrain',
            language: 'ru-RU'
        },
        {
            id: 66,
            category: 1,
            name: 'BBC News | Русская служба',
            description: 'Официальный канал Русской службы Би-би-си Ньюз',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch66.jpg',
            link: 'https://t.me/bbcrussian',
            language: 'ru-RU'
        },
        {
            id: 67,
            category: 1,
            name: 'Лентач',
            description: 'Официальный канал сообщества Лентач',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch67.jpg',
            link: 'https://t.me/oldlentach',
            language: 'ru-RU'
        },
        {
            id: 68,
            category: 1,
            name: 'Антиглянец',
            description: 'Глянцевое зазеркалье без цензуры.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch68.jpg',
            link: 'https://t.me/sncmag',
            language: 'ru-RU'
        },
        {
            id: 69,
            category: 1,
            name: 'Новая газета',
            description: 'Официальный канал «Новой газеты»',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch69.jpg',
            link: 'https://t.me/novaya_pishet',
            language: 'ru-RU'
        },
        {
            id: 70,
            category: 1,
            name: 'Москва 24',
            description: 'Круглосуточное городское СМИ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch70.jpg',
            link: 'https://t.me/infomoscow24',
            language: 'ru-RU'
        },
        {
            id: 71,
            category: 2,
            name: 'Elena Raytman',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch71.jpg',
            link: 'https://t.me/elenaraytman',
            language: 'ru-RU'
        },
        {
            id: 72,
            category: 2,
            name: 'Влад Бумага A4',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch72.jpg',
            link: 'https://t.me/A4omg',
            language: 'ru-RU'
        },
        {
            id: 73,
            category: 2,
            name: 'Бустер',
            description: 'Логово владельцев больший боевых боеголовок',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch73.jpg',
            link: 'https://t.me/bigpencil',
            language: 'ru-RU'
        },
        {
            id: 74,
            category: 2,
            name: 'Kuplinov ► Play',
            description: 'Официальный Телеграм-канал Куплинова',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch74.jpg',
            link: 'https://t.me/Kuplinov_Telegram',
            language: 'ru-RU'
        },
        {
            id: 75,
            category: 2,
            name: 'EDISON FAMILY',
            description: 'Перцы и пипуки',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch75.jpg',
            link: 'https://t.me/edisonfamilia',
            language: 'ru-RU'
        },
        {
            id: 76,
            category: 2,
            name: 'instasamka БЕАЧ',
            description: 'Единственный официальный канал INSTASAMKA.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch76.jpg',
            link: 'https://t.me/instasamkafuckyou',
            language: 'ru-RU'
        },
        {
            id: 77,
            category: 2,
            name: 'Exile',
            description: 'All about this dickhead',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch77.jpg',
            link: 'https://t.me/exilemusic13',
            language: 'ru-RU'
        },
        {
            id: 78,
            category: 2,
            name: 'Здесь живет броен',
            description: 'Привет, ребята помимо других соцсетей (пока они живы), здесь будет самая важная информация.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch78.jpg',
            link: 'https://t.me/br1anm7ps',
            language: 'ru-RU'
        },
        {
            id: 79,
            category: 2,
            name: 'GUSEINNEWS',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch79.jpg',
            link: 'https://t.me/guseinnews',
            language: 'ru-RU'
        },
        {
            id: 80,
            category: 2,
            name: 'Samoylovaoxana',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch80.jpg',
            link: 'https://t.me/sammyfam',
            language: 'ru-RU'
        },
        {
            id: 81,
            category: 2,
            name: 'MORGENSHTERN',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch81.jpg',
            link: 'https://t.me/alisherhateu',
            language: 'ru-RU'
        },
        {
            id: 82,
            category: 2,
            name: 'Marmok_yt',
            description: 'Привет! Это оффициальный канал "Marmok"',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch82.jpg',
            link: 'https://t.me/marmok_yt',
            language: 'ru-RU'
        },
        {
            id: 83,
            category: 2,
            name: 'Настя Ивлеева',
            description: 'Всё о вашей госпоже, слуге и рабыне',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch83.jpg',
            link: 'https://t.me/ivleeva360',
            language: 'ru-RU'
        },
        {
            id: 84,
            category: 2,
            name: 'Глент',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch84.jpg',
            link: 'https://t.me/vladglenttg',
            language: 'ru-RU'
        },
        {
            id: 85,
            category: 2,
            name: 'Артемий Лебедев',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch85.jpg',
            link: 'https://t.me/temalebedev',
            language: 'ru-RU'
        },
        {
            id: 86,
            category: 2,
            name: 'PETROVA',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch86.jpg',
            link: 'https://t.me/milanapetrovaqueen',
            language: 'ru-RU'
        },
        {
            id: 87,
            category: 2,
            name: 'ЕГОР КРИД',
            description: 'Официальный канал Егора Крида.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch87.jpg',
            link: 'https://t.me/egorkreed',
            language: 'ru-RU'
        },
        {
            id: 88,
            category: 4,
            name: 'Дмитрий Никотин',
            description: 'Про политику, простым языком о сложном.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch88.jpg',
            link: 'https://t.me/dmitrynikotin',
            language: 'ru-RU'
        },
        {
            id: 89,
            category: 4,
            name: 'Минобороны России',
            description: 'Официальный канал Министерства обороны Российской Федерации.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch89.jpg',
            link: 'https://t.me/mod_russia',
            language: 'ru-RU'
        },
        {
            id: 90,
            category: 4,
            name: 'Мария Захарова',
            description: 'Политика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch90.jpg',
            link: 'https://t.me/MariaVladimirovnaZakharova',
            language: 'ru-RU'
        },
        {
            id: 91,
            category: 4,
            name: 'Маргарита Симоньян',
            description: 'Политика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch91.jpg',
            link: 'https://t.me/margaritasimonyan',
            language: 'ru-RU'
        },
        {
            id: 92,
            category: 4,
            name: 'Сталингулаг',
            description: 'Александр Горбунов',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch92.jpg',
            link: 'https://t.me/stalin_gulag',
            language: 'ru-RU'
        },
        {
            id: 93,
            category: 4,
            name: 'Екатерина Шульман',
            description: 'Российский политолог, специалист по проблемам законотворчества.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch93.jpg',
            link: 'https://t.me/eschulmann',
            language: 'ru-RU'
        },
        {
            id: 94,
            category: 4,
            name: 'Навальный',
            description: 'Финальная битва между добром и нейтралитетом',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch94.jpg',
            link: 'https://t.me/navalny',
            language: 'ru-RU'
        },
        {
            id: 95,
            category: 4,
            name: 'Михаил Ходорковский',
            description: 'Политика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch95.jpg',
            link: 'https://t.me/khodorkovski',
            language: 'ru-RU'
        },
        {
            id: 96,
            category: 4,
            name: 'Мэр Москвы Сергей Собянин',
            description: 'Мэр Москвы',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch96.jpg',
            link: 'https://t.me/mos_sobyanin',
            language: 'ru-RU'
        },
        {
            id: 97,
            category: 4,
            name: 'ГАСПАРЯН',
            description: 'Писатель, телерадиоведущий. Первый зампред комиссии по просвещению и воспитанию Общественной палаты РФ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch97.jpg',
            link: 'https://t.me/ASGasparyan',
            language: 'ru-RU'
        },
        {
            id: 98,
            category: 4,
            name: 'Илья Яшин',
            description: 'Только правда, только хардкор',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch98.jpg',
            link: 'https://t.me/yashin_russia',
            language: 'ru-RU'
        },
        {
            id: 99,
            category: 4,
            name: 'Кремль. Новости',
            description: 'Кремль. Новости',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch99.jpg',
            link: 'https://t.me/news_kremlin',
            language: 'ru-RU'
        },
        {
            id: 100,
            category: 4,
            name: 'Команда Навального',
            description: 'Политика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch100.jpg',
            link: 'https://t.me/teamnavalny',
            language: 'ru-RU'
        },
        {
            id: 101,
            category: 4,
            name: 'Вован и Лексус',
            description: 'Официальный канал Вована и Лексуса',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch101.jpg',
            link: 'https://t.me/Russiacalling',
            language: 'ru-RU'
        },
        {
            id: 102,
            category: 4,
            name: 'МЕТАМЕТРИКА',
            description: '(Z) Официальный  telegram-канал проекта Metametrica',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch102.jpg',
            link: 'https://t.me/Metametrica',
            language: 'ru-RU'
        },
        {
            id: 103,
            category: 4,
            name: 'Правительство России',
            description: 'Официальный канал Правительства России',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch103.jpg',
            link: 'https://t.me/government_rus',
            language: 'ru-RU'
        },
        {
            id: 104,
            category: 4,
            name: 'Марат Хуснуллин',
            description: 'Заместитель Председателя Правительства Российской Федерации',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch104.jpg',
            link: 'https://t.me/mkhusnullin',
            language: 'ru-RU'
        },
        {
            id: 105,
            category: 4,
            name: 'Государственная Дума',
            description: 'Официальный канал Государственной Думы.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch105.jpg',
            link: 'https://t.me/duma_gov_ru',
            language: 'ru-RU'
        },
        {
            id: 106,
            category: 4,
            name: 'АНТОН ВЯЧЕСЛАВОВИЧ',
            description: 'Красовский врать не будет.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch106.jpg',
            link: 'https://t.me/krasovkin',
            language: 'ru-RU'
        },
        {
            id: 107,
            category: 4,
            name: 'МИД России',
            description: 'Официальный новостной канал МИД России',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch107.jpg',
            link: 'https://t.me/MID_Russia',
            language: 'ru-RU'
        },
        {
            id: 108,
            category: 4,
            name: 'Канал Максима Каца',
            description: 'Интересуюсь политикой и госуправлением, о чем рассказываю в своих видео, опираясь на научные данные. Когда-нибудь изберусь мэром Москвы :)',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch108.jpg',
            link: 'https://t.me/maximkatz',
            language: 'ru-RU'
        },
        {
            id: 109,
            category: 4,
            name: 'АГИТПРОП',
            description: 'Константин Сёмин (официальный канал)',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch109.jpg',
            link: 'https://t.me/Agitblog',
            language: 'ru-RU'
        },
        {
            id: 110,
            category: 5,
            name: 'Альфа-Банк',
            description: 'Оперативно рассказываем важные новости.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch110.jpg',
            link: 'https://t.me/AlfaBank',
            language: 'ru-RU'
        },
        {
            id: 111,
            category: 5,
            name: 'Сбер',
            description: 'Канал Сбера в Telegram. Рассказываем о финансах, сообщаем новости, делимся советами.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch111.jpg',
            link: 'https://t.me/sberbank',
            language: 'ru-RU'
        },
        {
            id: 112,
            category: 5,
            name: 'Тинькофф Инвестиции',
            description: 'Официальный канал Тинькофф.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch112.jpg',
            link: 'https://t.me/tinkoff_invest_official',
            language: 'ru-RU'
        },
        {
            id: 113,
            category: 5,
            name: 'Coin Post – Деньги, инвестиции, биткоин',
            description: 'На рынке с 2017г. Только выжимка в понятном формате.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch113.jpg',
            link: 'https://t.me/Coin_Post',
            language: 'ru-RU'
        },
        {
            id: 114,
            category: 5,
            name: 'bitkogan',
            description: 'Авторский канал Евгения Когана О деньгах и всем, что с ними связано',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch114.jpg',
            link: 'https://t.me/bitkogan',
            language: 'ru-RU'
        },
        {
            id: 115,
            category: 5,
            name: 'Тинькофф',
            description: 'Он такой один.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch115.jpg',
            link: 'https://t.me/tinkoffbank',
            language: 'ru-RU'
        },
        {
            id: 116,
            category: 5,
            name: 'ВТБ',
            description: 'Экономика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch116.jpg',
            link: 'https://t.me/bankvtb',
            language: 'ru-RU'
        },
        {
            id: 117,
            category: 5,
            name: 'Forbes Russia',
            description: 'Forbes — о бизнесе, экономике, финансах, карьере и стиле жизни.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch117.jpg',
            link: 'https://t.me/forbesrussia',
            language: 'ru-RU'
        },
        {
            id: 118,
            category: 5,
            name: 'СберИнвестиции',
            description: 'Канал СберИнвестиции, в котором мы рассказываем о новостях на рынках, инвестиционных прогнозах, инструментах и лайфхаках для начинающих и опытных инвесторов.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch118.jpg',
            link: 'https://t.me/SberInvestments',
            language: 'ru-RU'
        },
        {
            id: 119,
            category: 5,
            name: 'Альфа-Инвестиции',
            description: 'Официальный канал Альфа-Инвестиций с инвестидеями, прогнозами и советами, как управлять портфелем.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch119.jpg',
            link: 'https://t.me/alfa_investments',
            language: 'ru-RU'
        },
        {
            id: 120,
            category: 5,
            name: 'InvestFuture',
            description: 'Медиа про деньги',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch120.jpg',
            link: 'https://t.me/investfuture',
            language: 'ru-RU'
        },
        {
            id: 121,
            category: 5,
            name: 'Банк России',
            description: 'Экономика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch121.jpg',
            link: 'https://t.me/centralbank_russia',
            language: 'ru-RU'
        },
        {
            id: 122,
            category: 5,
            name: 'Т—Ж',
            description: 'Канал Тинькофф Журнала.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch122.jpg',
            link: 'https://t.me/tinkoffjournal',
            language: 'ru-RU'
        },
        {
            id: 123,
            category: 5,
            name: 'СберСпасибо',
            description: 'Экономика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch123.jpg',
            link: 'https://t.me/sber_spasibo',
            language: 'ru-RU'
        },
        {
            id: 124,
            category: 5,
            name: 'Карта «Халва»',
            description: 'Карта рассрочки «Халва» Рассрочка 0% от 10 месяцев, бесплатное обслуживание, кэшбэк до 10%, доход на остаток до 15% с подпиской «Халва.Десятка»',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch124.jpg',
            link: 'https://t.me/halvacard_official',
            language: 'ru-RU'
        },
        {
            id: 125,
            category: 5,
            name: 'Газпромбанк',
            description: 'Официальный канал Газпромбанка в Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch125.jpg',
            link: 'https://t.me/gazprombank',
            language: 'ru-RU'
        },
        {
            id: 126,
            category: 5,
            name: 'IF Stocks',
            description: 'Аналитика от команды InvestFuture',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch126.jpg',
            link: 'https://t.me/if_stocks',
            language: 'ru-RU'
        },
        {
            id: 127,
            category: 5,
            name: 'IF News',
            description: 'Оперативные новости от команды InvestFuture',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch127.jpg',
            link: 'https://t.me/if_market_news',
            language: 'ru-RU'
        },
        {
            id: 128,
            category: 5,
            name: 'Fin-Review: экономика и инвестиции',
            description: 'Экономика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch128.jpg',
            link: 'https://t.me/fin_review_ru',
            language: 'ru-RU'
        },
        {
            id: 129,
            category: 5,
            name: 'Хулиномика',
            description: 'О финансах человеческим языком от автора лучшего в мире учебника.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch129.jpg',
            link: 'https://t.me/hoolinomics',
            language: 'ru-RU'
        },
        {
            id: 130,
            category: 5,
            name: 'RationalAnswer | Павел Комаровский',
            description: 'Разумные ответы на жизненные вопросы про финансы, инвестиции, и многое другое.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch130.jpg',
            link: 'https://t.me/RationalAnswer',
            language: 'ru-RU'
        },
        {
            id: 131,
            category: 5,
            name: 'Finam Alert',
            description: 'Finam Alert (Инвестиционный брокер "Финам") – это рыночные сигналы, идеи, торговые прогнозы, обзор IPO (российская и зарубежные биржи). Не являются индивидуальными инвестиционными рекомендациями!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch131.jpg',
            link: 'https://t.me/finamalert',
            language: 'ru-RU'
        },
        {
            id: 132,
            category: 5,
            name: 'Alfa Wealth',
            description: 'Канал инвестиционного консультанта из УК Альфа-Капитал.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch132.jpg',
            link: 'https://t.me/alfawealth',
            language: 'ru-RU'
        },
        {
            id: 133,
            category: 5,
            name: 'Аналитика Тинькофф Инвестиций',
            description: 'Обзоры, инвестидеи и комментарии от аналитиков Тинькофф Инвестиций',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch133.jpg',
            link: 'https://t.me/tinkoff_analytics_official',
            language: 'ru-RU'
        },
        {
            id: 134,
            category: 6,
            name: '!Finuniver',
            description: 'Официальный телеграм-канал Финансового университета',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch134.jpg',
            link: 'https://t.me/finuniverchan',
            language: 'ru-RU'
        },
        {
            id: 135,
            category: 6,
            name: 'Вышка для своих',
            description: 'Тут мы рассказываем о жизни университета, самых интересных событиях и важных новостях для студентов и сотрудников. Не теряйтесь!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch135.jpg',
            link: 'https://t.me/hse_live',
            language: 'ru-RU'
        },
        {
            id: 136,
            category: 6,
            name: 'УрФУ',
            description: 'Один хороший университет.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch136.jpg',
            link: 'https://t.me/urfu_ru',
            language: 'ru-RU'
        },
        {
            id: 137,
            category: 6,
            name: 'Plekhanov Universe',
            description: 'Официальный телеграм-канал РЭУ им. Г.В. Плеханова',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch137.jpg',
            link: 'https://t.me/PlekhanovUniverse',
            language: 'ru-RU'
        },
        {
            id: 138,
            category: 6,
            name: 'Университет «Синергия»',
            description: 'Важная информация для абитуриентов, студентов, выпускников и сотрудников «Синергии».',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch139.jpg',
            link: 'https://t.me/synergyunivers',
            language: 'ru-RU'
        },
        {
            id: 140,
            category: 6,
            name: 'ДВФУ',
            description: 'Дальневосточный федеральный университет (ДВФУ)',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch140.jpg',
            link: 'https://t.me/fefudvfu',
            language: 'ru-RU'
        },
        {
            id: 141,
            category: 6,
            name: 'Московский Политех',
            description: 'Официальный канал Московского политехнического университета',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch141.jpg',
            link: 'https://t.me/mospolytech',
            language: 'ru-RU'
        },
        {
            id: 142,
            category: 6,
            name: 'МАИ',
            description: 'Всё самое актуальное из жизни Московского авиационного института',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch142.jpg',
            link: 'https://t.me/MAIuniversity',
            language: 'ru-RU'
        },
        {
            id: 143,
            category: 6,
            name: 'СФУ',
            description: 'Официальный телеграм-канал Сибирского федерального университета.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch143.jpg',
            link: 'https://t.me/SibFUofficial',
            language: 'ru-RU'
        },
        {
            id: 144,
            category: 6,
            name: '#ПолитехПетра на связи',
            description: 'Официальный телеграм-канал СПбПУ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch144.jpg',
            link: 'https://t.me/polytech_petra',
            language: 'ru-RU'
        },
        {
            id: 145,
            category: 6,
            name: 'РАНХиГС. Новости',
            description: 'Официальный канал Президентской академии.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch145.jpg',
            link: 'https://t.me/mainranepa',
            language: 'ru-RU'
        },
        {
            id: 146,
            category: 6,
            name: 'КубГУ | kubsunews',
            description: 'Все о жизни Кубанского государственного университета',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch146.jpg',
            link: 'https://t.me/kubsunews',
            language: 'ru-RU'
        },
        {
            id: 147,
            category: 6,
            name: 'ТГУ | Томский государственный университет',
            description: 'Первый в Сибири',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch147.jpg',
            link: 'https://t.me/tomskuniversity',
            language: 'ru-RU'
        },
        {
            id: 148,
            category: 6,
            name: 'МФТИ — Физтех',
            description: 'Новости Физтеха.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch148.jpg',
            link: 'https://t.me/miptru',
            language: 'ru-RU'
        },
        {
            id: 149,
            category: 6,
            name: 'МГИМО. Официально',
            description: 'Образование',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch149.jpg',
            link: 'https://t.me/mgimo_university',
            language: 'ru-RU'
        },
        {
            id: 150,
            category: 7,
            name: 'Авиакомпания «Победа»',
            description: 'Официальный канал авиакомпании «Победа»',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch150.jpg',
            link: 'https://t.me/PobedaAirlines',
            language: 'ru-RU'
        },
        {
            id: 151,
            category: 7,
            name: 'Необычные путешествия',
            description: 'Канал о поездках в редкие, опасные и экзотические страны.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch151.jpg',
            link: 'https://t.me/mikearoundtheworld',
            language: 'ru-RU'
        },
        {
            id: 152,
            category: 7,
            name: 'АЭРОФЛОТ',
            description: 'Официальный канал авиакомпании Аэрофлот. Подписывайтесь и узнавайте первыми о наших событиях, акциях, новых услугах, направлениях и о многом другом, что полезно для авиапутешественников.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch152.jpg',
            link: 'https://t.me/aeroflot_official',
            language: 'ru-RU'
        },
        {
            id: 153,
            category: 7,
            name: 'Авиасейлс',
            description: 'Привет, это канал Авиасейлс. Мы помогаем находить дешевые авиабилеты.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch153.jpg',
            link: 'https://t.me/aviasales',
            language: 'ru-RU'
        },
        {
            id: 154,
            category: 7,
            name: 'OneTwoTrip!',
            description: 'Приложение для организации путешествий',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch154.jpg',
            link: 'https://t.me/OneTwoTrip',
            language: 'ru-RU'
        },
        {
            id: 155,
            category: 7,
            name: 'S7 Airlines',
            description: 'Официальный канал авиакомпании S7 Airlines.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch155.jpg',
            link: 'https://t.me/s7airlines',
            language: 'ru-RU'
        },
        {
            id: 156,
            category: 7,
            name: 'Туту',
            description: 'Туту: новости путешествий и туризма простым языком, всё важное, что происходит сейчас плюс немного странного.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch156.jpg',
            link: 'https://t.me/tutu_travel',
            language: 'ru-RU'
        },
        {
            id: 157,
            category: 7,
            name: 'Яндекс Путешествия',
            description: 'Канал для тех, кто хочет путешествовать легко, интересно и выгодно.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch157.jpg',
            link: 'https://t.me/yandex_travel',
            language: 'ru-RU'
        },
        {
            id: 158,
            category: 7,
            name: 'Nellifornication тут',
            description: 'Хай гайс и велком на легендарный канал Nellifornication. В ТОП-10 лучших тревел-блогов, тут много движа из Лос-Анджелеса и бэкстейджа как создавать контент на миллион',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch158.jpg',
            link: 'https://t.me/nellifornication_official',
            language: 'ru-RU'
        },
        {
            id: 159,
            category: 7,
            name: 'KudaGo: Петербург',
            description: 'Афиша и лучшие места Санкт-Петербурга (СПБ).',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch159.jpg',
            link: 'https://t.me/kudagospb',
            language: 'ru-RU'
        },
        {
            id: 160,
            category: 7,
            name: 'Крыша ТурДома',
            description: 'Новости туризма. Туристический портал',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch160.jpg',
            link: 'https://t.me/tourdom',
            language: 'ru-RU'
        },
        {
            id: 161,
            category: 7,
            name: 'Checkintime.ru - Путешествия,авиабилеты,горящие туры!',
            description: 'Отдыхайте больше, платите меньше',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch161.jpg',
            link: 'https://t.me/checkintime',
            language: 'ru-RU'
        },
        {
            id: 162,
            category: 7,
            name: 'Марк Ерёмин',
            description: 'Меня зовут Марк, я - путешественник. Я покажу тебе самые необычные места России и мира',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch162.jpg',
            link: 'https://t.me/markeremintravel',
            language: 'ru-RU'
        },
        {
            id: 163,
            category: 7,
            name: 'Т—Ж Чемодан',
            description: 'Самое важное о других странах: где отдыхать, учиться и жить. Как путешествовать за копейки и не переплачивать в отпуске by Т—Ж',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch163.jpg',
            link: 'https://t.me/t_chemodan',
            language: 'ru-RU'
        },
        {
            id: 164,
            category: 7,
            name: 'Cuva',
            description: 'Мы — команда Cuva. С помощью нашего сервиса можно подарить близким отдых в отелях России',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch164.jpg',
            link: 'https://t.me/hellocuva',
            language: 'ru-RU'
        },
        {
            id: 165,
            category: 7,
            name: 'Понаехали in Canada',
            description: 'Путешествия',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch165.jpg',
            link: 'https://t.me/Canada_in',
            language: 'ru-RU'
        },
        {
            id: 166,
            category: 7,
            name: 'Александр Михайленко',
            description: 'Всё про Крым',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch166.jpg',
            link: 'https://t.me/crimealove',
            language: 'ru-RU'
        },
        {
            id: 167,
            category: 7,
            name: 'Ostrovok.ru',
            description: 'Мы — cервис онлайн-бронирования отелей в 220 странах мира. Мы предлагаем более 2 000 000 вариантов размещения (из них более 92 000 в России) по разумным ценам.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch167.jpg',
            link: 'https://t.me/ostrovok_travel',
            language: 'ru-RU'
        },
        {
            id: 168,
            category: 7,
            name: 'Ростуризм',
            description: 'О жизни российского туризма',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch168.jpg',
            link: 'https://t.me/rostourism_official',
            language: 'ru-RU'
        },
        {
            id: 169,
            category: 7,
            name: 'Smartavia',
            description: 'Оперативные новости о самых выгодных тарифах, новых услугах и изменениях в работе авиакомпании. Присоединяйтесь!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch169.jpg',
            link: 'https://t.me/Flysmartavia',
            language: 'ru-RU'
        },
        {
            id: 170,
            category: 7,
            name: 'FUN&SUN',
            description: 'Здесь вас ждёт множество подборок туров и отелей в разные города и страны',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch170.jpg',
            link: 'https://t.me/funsuntravel',
            language: 'ru-RU'
        },
        {
            id: 171,
            category: 7,
            name: '@elivosk',
            description: 'Привет! Я Илия, обычный человек, муж, папа, фотограф и немного блогер. Этот канал создан для того, чтобы делиться с вами новостями из мира путешествий и частичкой своей жизни:).',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch171.jpg',
            link: 'https://t.me/elivosk',
            language: 'ru-RU'
        },
        {
            id: 172,
            category: 7,
            name: 'Perito | Медиа о культуре и территориях',
            description: 'Делаем постколониализм общим знанием.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch172.jpg',
            link: 'https://t.me/prtbrt',
            language: 'ru-RU'
        },
        {
            id: 173,
            category: 7,
            name: 'Остров Мечты',
            description: 'Пространство для развлечений и отдыха',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch173.jpg',
            link: 'https://t.me/dreamislandmsk',
            language: 'ru-RU'
        },
        {
            id: 174,
            category: 8,
            name: 'ОПЕРШТАБ РЫБАКОВ ИГОРЬ',
            description: 'Официальный канал Игоря Рыбакова (знак верификации)',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch174.jpg',
            link: 'https://t.me/rybakovigor',
            language: 'ru-RU'
        },
        {
            id: 175,
            category: 8,
            name: 'WB Партнеры',
            description: 'Официальный телеграм-канал Wildberries для предпринимателей',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch175.jpg',
            link: 'https://t.me/wbsellerofficial',
            language: 'ru-RU'
        },
        {
            id: 176,
            category: 8,
            name: 'Трансформатор',
            description: 'Добро пожаловать в мой LIVE канал. Тут я публикую яркие моменты жизни и моих бизнесов, а также актуальные новости и другие материалы о бизнесе',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch176.jpg',
            link: 'https://t.me/TransformatorTV',
            language: 'ru-RU'
        },
        {
            id: 177,
            category: 8,
            name: 'Ozon Marketplace',
            description: 'Канал для продавцов Ozon Marketplace и всех заинтересованных: новости площадки, обучающий контент и бонусы от партнёров.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch177.jpg',
            link: 'https://t.me/ozonmarketplace',
            language: 'ru-RU'
        },
        {
            id: 178,
            category: 8,
            name: 'Мой бизнес',
            description: 'Главные новости для малого бизнеса и самозанятых',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch178.jpg',
            link: 'https://t.me/moibiz',
            language: 'ru-RU'
        },
        {
            id: 179,
            category: 8,
            name: 'Стартап дня. Александр Горный.',
            description: 'Авторский канал о стартапах и интернет-бизнесе. Подробнее - в закрепленном сообщении.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch179.jpg',
            link: 'https://t.me/startupoftheday',
            language: 'ru-RU'
        },
        {
            id: 180,
            category: 8,
            name: 'Яндекс для предпринимателей',
            description: 'Помогаем предпринимателям упростить работу и освободить время для других важных дел.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch180.jpg',
            link: 'https://t.me/yandexbusiness',
            language: 'ru-RU'
        },
        {
            id: 181,
            category: 8,
            name: 'Ozon и партнёры: live news',
            description: 'Канал для владельцев и сотрудников пунктов выдачи заказов Ozon',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch181.jpg',
            link: 'https://t.me/ozonapvz',
            language: 'ru-RU'
        },
        {
            id: 182,
            category: 8,
            name: 'СберБизнес',
            description: 'Всегда рядом, чтобы помочь вашему бизнесу! Актуальные новости, полезные советы и истории реальных предпринимателей и самозанятых, ответы на частые вопросы, обзор услуг для клиентов СберБизнеса — на нашем канале.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch182.jpg',
            link: 'https://t.me/sberbusiness',
            language: 'ru-RU'
        },
        {
            id: 183,
            category: 8,
            name: 'Sokolovskiy',
            description: 'Предприниматель. Муж и отец',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch183.jpg',
            link: 'https://t.me/sokolay',
            language: 'ru-RU'
        },
        {
            id: 184,
            category: 8,
            name: 'Циан для профи',
            description: 'Официальный новостной канал Циан для профи',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch184.jpg',
            link: 'https://t.me/cian_realtor',
            language: 'ru-RU'
        },
        {
            id: 185,
            category: 8,
            name: 'Александр Высоцкий',
            description: 'Основатель акселератора Business Booster.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch185.jpg',
            link: 'https://t.me/alexander_visotsky',
            language: 'ru-RU'
        },
        {
            id: 186,
            category: 8,
            name: 'Авито для бизнеса',
            description: 'Привет! Это канал для тех, кто ведёт бизнес или решает профессиональные задачи на Авито. Здесь мы делимся аналитикой рынка и решениями, как бизнесу справиться с разными ситуациями. Рассказываем, как развивать своё дело на нашей платформе.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch186.jpg',
            link: 'https://t.me/avito_b2b',
            language: 'ru-RU'
        },
        {
            id: 187,
            category: 8,
            name: 'Максим Спиридонов',
            description: 'О бизнесе, лидерстве и будущем от серийного предпринимателя, сооснователя бизнес-клуба Reforma и экосистемы Wonder Family.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch187.jpg',
            link: 'https://t.me/avito_b2b',
            language: 'ru-RU'
        },
        {
            id: 188,
            category: 8,
            name: 'Корпорация МСП',
            description: 'Если вы микро-, малый и средний бизнес, вы по адресу!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch188.jpg',
            link: 'https://t.me/corpmspof',
            language: 'ru-RU'
        },
        {
            id: 189,
            category: 8,
            name: 'Яндекс Маркет для продавцов',
            description: 'Канал Маркета с новостями для продавцов и производителей.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch189.jpg',
            link: 'https://t.me/market_marketplace',
            language: 'ru-RU'
        },
        {
            id: 190,
            category: 8,
            name: 'Скидки на Маркете',
            description: 'Канал в котором мы публикуем информацию о промокодах, акциях, скидках и распродажах на Яндекс Маркете',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch190.jpg',
            link: 'https://t.me/marketbenefit',
            language: 'ru-RU'
        },
        {
            id: 191,
            category: 8,
            name: 'Бизнес-секреты: маркетплейсы',
            description: 'Канал Бизнес-секретов для тех, кто продает на маркетплейсах.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch191.jpg',
            link: 'https://t.me/bs_marketplace',
            language: 'ru-RU'
        },
        {
            id: 192,
            category: 8,
            name: 'Skolkovo School of Management',
            description: 'Официальный канал Школы управления Сколково. Статьи, исследования, кейсы для руководителей и владельцев бизнеса.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch192.jpg',
            link: 'https://t.me/skolkovo_channel',
            language: 'ru-RU'
        },
        {
            id: 193,
            category: 8,
            name: 'Наука Побеждать Эда Халилова',
            description: 'Здесь формируется сообщество, которое знает, как обезопасить себя и свою семью',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch193.jpg',
            link: 'https://t.me/naukapobezhdat',
            language: 'ru-RU'
        },
        {
            id: 194,
            category: 8,
            name: 'Самолет Плюс Business',
            description: 'Начни бизнес под руководством сильного бренда!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch194.jpg',
            link: 'https://t.me/samolet_plus_business',
            language: 'ru-RU'
        },
        {
            id: 195,
            category: 8,
            name: 'ГК ФСК',
            description: 'Создаем места силы для наших клиентов.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch195.jpg',
            link: 'https://t.me/gk_fsk',
            language: 'ru-RU'
        },
        {
            id: 196,
            category: 8,
            name: 'Банк Точка',
            description: 'Банк для предпринимателей и предприятий',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch196.jpg',
            link: 'https://t.me/tochka',
            language: 'ru-RU'
        },
        {
            id: 197,
            category: 2,
            name: 'КЛАВА КОКА',
            description: 'Эксклюзивные фото, новости, мысли и творчество',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch197.jpg',
            link: 'https://t.me/klavacoca',
            language: 'ru-RU'
        },
        {
            id: 198,
            category: 2,
            name: 'Arnold Schwarzenegger',
            description: 'Yes, it’s me!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch198.jpg',
            link: 'https://t.me/AASchwarzenegger',
            language: 'en-EN'
        },
        {
            id: 199,
            category: 3,
            name: 'TON Community',
            description: 'The Open Network (TON) is putting crypto in every pocket. By building a Web3 ecosystem within Telegram Messenger, TON is giving billions the opportunity to own their digital identity, data, and assets.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch199.jpg',
            link: 'https://t.me/toncoin',
            language: 'en-EN'
        },
        {
            id: 200,
            category: 1,
            name: 'Durov’s Code',
            description: 'News from IT and Telegram world.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch200.jpg',
            link: 'https://t.me/durovs_code',
            language: 'en-EN'
        },
        {
            id: 201,
            category: 2,
            name: 'Du Rove’s Channel',
            description: 'Thoughts from the Product Manager / CEO / Founder of Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch201.jpg',
            link: 'https://t.me/durov',
            language: 'en-EN'
        },
        {
            id: 202,
            category: 3,
            name: 'Polygon Official Announcements',
            description: 'Polygon - Ethereum’s Internet of blockchains, offers a wide range of secure, fast, affordable and energy-efficient Ethereum scaling solutions for Web 3 developers.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch202.jpg',
            link: 'https://t.me/PolygonAnnouncements',
            language: 'en-EN'
        },
        {
            id: 203,
            category: 2,
            name: 'Бузова',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch203.jpg',
            link: 'https://t.me/byzovahouse',
            language: 'ru-RU'
        },
        {
            id: 204,
            category: 2,
            name: 'Полина Гагарина',
            description: 'Официальный телеграмм канал Полины Гагариной',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch204.jpg',
            link: 'https://t.me/gagara1987official',
            language: 'ru-RU'
        },
        {
            id: 205,
            category: 2,
            name: 'Валерия',
            description: 'Музыка и не только',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch205.jpg',
            link: 'https://t.me/valeriyaofficial',
            language: 'ru-RU'
        },
        {
            id: 206,
            category: 2,
            name: 'Голубь Мира Яна Рудковская',
            description: 'Официальный канал Яны Рудковской',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch206.jpg',
            link: 'https://t.me/doveoftheworld',
            language: 'ru-RU'
        },
        {
            id: 207,
            category: 2,
            name: 'Сергей Лазарев',
            description: 'Официальный канал',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch207.jpg',
            link: 'https://t.me/lazarev_official',
            language: 'ru-RU'
        },
        {
            id: 208,
            category: 2,
            name: 'Алексей Воробьев',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch208.jpg',
            link: 'https://t.me/alexsparrow_official',
            language: 'ru-RU'
        },
        {
            id: 209,
            category: 2,
            name: 'МАКSИМ',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch209.jpg',
            link: 'https://t.me/marina_maksim',
            language: 'ru-RU'
        },
        {
            id: 210,
            category: 2,
            name: 'Кристина Орбакайте',
            description: 'Официальный канал Кристины Орбакайте',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch210.jpg',
            link: 'https://t.me/orbakaite_k_telegram',
            language: 'ru-RU'
        },
        {
            id: 211,
            category: 2,
            name: 'NYUSHA TEAM',
            description: 'Это официальный канал певицы Nyusha. Подписывайтесь',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch211.jpg',
            link: 'https://t.me/nyushateam',
            language: 'ru-RU'
        },
        {
            id: 212,
            category: 2,
            name: 'МАЛАХОВ',
            description: 'Берегите себя и своих близких',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch212.jpg',
            link: 'https://t.me/and_malakhov',
            language: 'ru-RU'
        },
        {
            id: 213,
            category: 2,
            name: 'Лера Кудрявцева',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch213.jpg',
            link: 'https://t.me/lerakudryavtseva',
            language: 'ru-RU'
        },
        {
            id: 214,
            category: 2,
            name: 'ТИМУР РОДРИГЕЗ',
            description: 'Официальный канал Тимура Родригеза в Telegram',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch214.jpg',
            link: 'https://t.me/trodriguez_official',
            language: 'ru-RU'
        },
        {
            id: 215,
            category: 2,
            name: 'ЦИСКАРИДЗЕ•NEWS',
            description: 'ИСКУССТВО и ЖИЗНЬ Официальный канал Николая Цискаридзе',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch215.jpg',
            link: 'https://t.me/tsiskaridzenews',
            language: 'ru-RU'
        },
        {
            id: 216,
            category: 2,
            name: 'Samburskaya',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch216.jpg',
            link: 'https://t.me/samburskayan',
            language: 'ru-RU'
        },
        {
            id: 217,
            category: 2,
            name: 'Кристина Асмус',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch217.jpg',
            link: 'https://t.me/asmusk',
            language: 'ru-RU'
        },
        {
            id: 218,
            category: 2,
            name: 'Анастасия Волочкова',
            description: 'Рады приветствовать Вас в официальном Telegram-канале Анастасии Волочковой',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch218.jpg',
            link: 'https://t.me/volochkova_anastasiya',
            language: 'ru-RU'
        },
        {
            id: 219,
            category: 2,
            name: 'ДЖИГАН',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch219.jpg',
            link: 'https://t.me/geegunchill',
            language: 'ru-RU'
        },
        {
            id: 220,
            category: 2,
            name: 'The Limba',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch220.jpg',
            link: 'https://t.me/thelimbaa',
            language: 'ru-RU'
        },
        {
            id: 221,
            category: 2,
            name: 'SLAVA MARLOW',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch221.jpg',
            link: 'https://t.me/slavamarlow',
            language: 'ru-RU'
        },
        {
            id: 222,
            category: 2,
            name: 'Michael Galustyan',
            description: 'Здесь только позитив!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch222.jpg',
            link: 'https://t.me/galustyanmichael',
            language: 'ru-RU'
        },
        {
            id: 223,
            category: 2,
            name: 'Павел Воля',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch223.jpg',
            link: 'https://t.me/pvolya',
            language: 'ru-RU'
        },
        {
            id: 224,
            category: 2,
            name: 'ШАСТУН',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch224.jpg',
            link: 'https://t.me/shastoonchannel',
            language: 'ru-RU'
        },
        {
            id: 225,
            category: 2,
            name: 'Арсений',
            description: 'Официальный ТГК Арсения Попова',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch225.jpg',
            link: 'https://t.me/arsdvatri',
            language: 'ru-RU'
        },
        {
            id: 226,
            category: 2,
            name: 'СЕРГЕЙ МАТВИЕНКО',
            description: 'канал Серёжи Матвиенко',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch226.jpg',
            link: 'https://t.me/yamamatvienko',
            language: 'ru-RU'
        },
        {
            id: 227,
            category: 2,
            name: 'DAVA TV',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch227.jpg',
            link: 'https://t.me/davatelega',
            language: 'ru-RU'
        },
        {
            id: 228,
            category: 2,
            name: 'UsachevRuslan',
            description: 'Awesomest channel ever',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch228.jpg',
            link: 'https://t.me/usachevruslan',
            language: 'ru-RU'
        },
        {
            id: 229,
            category: 2,
            name: 'Wylsacom Red',
            description: 'Единственный легальный канал Wylsacom Media.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch229.jpg',
            link: 'https://t.me/Wylsared',
            language: 'ru-RU'
        },
        {
            id: 230,
            category: 2,
            name: 'IdaGalich',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch230.jpg',
            link: 'https://t.me/GALICH_onok',
            language: 'ru-RU'
        },
        {
            id: 231,
            category: 2,
            name: 'Юлик',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch231.jpg',
            link: 'https://t.me/juliusspeak',
            language: 'ru-RU'
        },
        {
            id: 232,
            category: 2,
            name: 'VODA',
            description: 'Alena Vodonaeva',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch232.jpg',
            link: 'https://t.me/Avodonaeva',
            language: 'ru-RU'
        },
        {
            id: 233,
            category: 2,
            name: 'Вероника Степанова',
            description: 'Мысли',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch233.jpg',
            link: 'https://t.me/veronikastepanova20011',
            language: 'ru-RU'
        },
        {
            id: 234,
            category: 2,
            name: 'Артём Дзюба',
            description: 'Официальный телеграм-канал футболиста Артёма Дзюбы',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch234.jpg',
            link: 'https://t.me/dzyubaartem22',
            language: 'ru-RU'
        },
        {
            id: 235,
            category: 2,
            name: 'Алексей Ягудин',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch235.jpg',
            link: 'https://t.me/yagudin_official',
            language: 'ru-RU'
        },
        {
            id: 236,
            category: 2,
            name: 'Илья Авербух',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch236.jpg',
            link: 'https://t.me/averbukh_ilya',
            language: 'ru-RU'
        },
        {
            id: 237,
            category: 2,
            name: 'Kamila Valieva',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch237.jpg',
            link: 'https://t.me/kamilavalieva26official',
            language: 'ru-RU'
        },
        {
            id: 238,
            category: 2,
            name: 'Алина Загитова',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch238.jpg',
            link: 'https://t.me/azagitova_official',
            language: 'ru-RU'
        },
        {
            id: 239,
            category: 2,
            name: 'Anna Shcherbakova',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch239.jpg',
            link: 'https://t.me/shch_anna',
            language: 'ru-RU'
        },
        {
            id: 240,
            category: 2,
            name: 'Evgenia Medvedeva',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch240.jpg',
            link: 'https://t.me/jmedvedevaj_official',
            language: 'ru-RU'
        },
        {
            id: 241,
            category: 5,
            name: 'Банк Уралсиб',
            description: 'Присоединяйтесь, чтобы первыми узнавать всё о новых продуктах и акциях Банка Уралсиб.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch241.jpg',
            link: 'https://t.me/bankuralsibnews',
            language: 'ru-RU'
        },
        {
            id: 242,
            category: 4,
            name: 'Россельхознадзор',
            description: 'Официальный аккаунт Федеральной службы по ветеринарному и фитосанитарному надзору',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch242.jpg',
            link: 'https://t.me/fsvps_official',
            language: 'ru-RU'
        },
        {
            id: 243,
            category: 2,
            name: 'Павел Дуров',
            description: 'Канал для русскоязычной аудитории. Основной канал, который я обновляю чаще, – англоязычный',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch243.jpg',
            link: 'https://t.me/durov_russia',
            language: 'ru-RU'
        },
        {
            id: 244,
            category: 2,
            name: 'Екатерина Волкова',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch244.jpg',
            link: 'https://t.me/ekaterinavolkovaactress',
            language: 'ru-RU'
        },
        {
            id: 245,
            category: 2,
            name: 'Jackson Hinkle',
            description: 'The Dive with Jackson Hinkle',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch245.jpg',
            link: 'https://t.me/jacksonhinkle',
            language: 'en-EN'
        },
        {
            id: 246,
            category: 4,
            name: 'Jack Posobiec',
            description: 'Politics',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch246.jpg',
            link: 'https://t.me/Jack_Posobiec',
            language: 'en-EN'
        },
        {
            id: 247,
            category: 4,
            name: 'Mike Lindell',
            description: 'Inventor and CEO of MyPillow, Author of "What Are the Odds? From Crack Addict to CEO,” Executive Producer, Creator of MyStore and Lindell Recovery Network',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch247.jpg',
            link: 'https://t.me/MichaelJLindell',
            language: 'en-EN'
        },
        {
            id: 248,
            category: 2,
            name: 'MIKE COUDREY',
            description: 'Entrepreneur. Investor. Activist.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch248.jpg',
            link: 'https://t.me/MichaelCoudrey',
            language: 'en-EN'
        },
        {
            id: 249,
            category: 4,
            name: 'Michael Johns',
            description: 'Michael Johns is co-founder of the U.S. Tea Party movement and a former White House speechwriter and Heritage Foundation policy analyst. He has written for The Wall Street Journal, The Christian Science Monitor, National Review and other media.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch249.jpg',
            link: 'https://t.me/michaeljohns1',
            language: 'en-EN'
        },
        {
            id: 250,
            category: 4,
            name: 'James O’Keefe',
            description: 'Guerrilla Journalist',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch250.jpg',
            link: 'https://t.me/JamesOKeefeIII',
            language: 'en-EN'
        },
        {
            id: 251,
            category: 3,
            name: 'Litecoin',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch251.jpg',
            link: 'https://t.me/Litecoin',
            language: 'en-EN'
        },
        {
            id: 252,
            category: 3,
            name: 'Chainlink Official',
            description: 'The Offical Telegram of the ChainLink Community (Please see our pinned post for rules and resources)',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch252.jpg',
            link: 'https://t.me/chainlinkofficial',
            language: 'en-EN'
        },
        {
            id: 253,
            category: 3,
            name: 'Coingraph | News',
            description: 'Unparalleled and unbiased source of crypto news about',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch253.jpg',
            link: 'https://t.me/CoingraphNews',
            language: 'en-EN'
        },
        {
            id: 254,
            category: 1,
            name: 'Telegram News',
            description: 'The official Telegram on Telegram. Much recursion. Very Telegram. Wow.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch254.jpg',
            link: 'https://t.me/telegram',
            language: 'en-EN'
        },
        {
            id: 255,
            category: 1,
            name: 'Bloomberg',
            description: 'The first word in business news.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch255.jpg',
            link: 'https://t.me/bloomberg',
            language: 'en-EN'
        },
        {
            id: 256,
            category: 1,
            name: 'Bloomberg Law',
            description: 'Where tech nerds can find the biggest crypto, data, and privacy law news of the day.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch256.jpg',
            link: 'https://t.me/bloomberglaw',
            language: 'en-EN'
        },
        {
            id: 257,
            category: 1,
            name: 'The Washington Post',
            description: 'The official Washington Post channel, sharing live news coverage of Russia’s war in Ukraine. You can find our full coverage at',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch257.jpg',
            link: 'https://t.me/washingtonpost',
            language: 'en-EN'
        },
        {
            id: 258,
            category: 1,
            name: 'USA TODAY',
            description: 'News, photos, videos and fact checks from the Russia-Ukraine war. Latest updates at',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch258.jpg',
            link: 'https://t.me/usatoday',
            language: 'en-EN'
        },
        {
            id: 259,
            category: 1,
            name: 'CNBCTV18.com',
            description: 'The official Telegram channel',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch259.jpg',
            link: 'https://t.me/cnbc_tv18',
            language: 'en-EN'
        },
        {
            id: 260,
            category: 1,
            name: 'The New York Times',
            description: 'New York Times coverage from around the world, including the Russia-Ukraine war.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch260.jpg',
            link: 'https://t.me/nytimes',
            language: 'en-EN'
        },
        {
            id: 261,
            category: 1,
            name: 'Politico',
            description: 'Sharing the most important stories and updates leading up to the 2024 U.S. presidential election',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch261.jpg',
            link: 'https://t.me/politico',
            language: 'en-EN'
        },
        {
            id: 262,
            category: 1,
            name: 'Newsweek',
            description: 'News, in-depth analysis and ideas about international issues, technology, business, culture and politics.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch262.jpg',
            link: 'https://t.me/newsweek',
            language: 'en-EN'
        },
        {
            id: 263,
            category: 1,
            name: 'News Update',
            description: 'Latest news update',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch263.jpg',
            link: 'https://t.me/siasatdaily',
            language: 'en-EN'
        },
        {
            id: 264,
            category: 1,
            name: 'UK breaking news - The Sun',
            description: 'Breaking news on the Ukraine war, as it happens, direct from The Sun',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch264.jpg',
            link: 'https://t.me/ukbreaking',
            language: 'en-EN'
        },
        {
            id: 265,
            category: 3,
            name: 'Tonkeeper News',
            description: 'Tonkeeper wallet release notes.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch7.jpg',
            link: 'https://t.me/tonkeeper_news',
            language: 'en-EN'
        },
        {
            id: 266,
            category: 2,
            name: 'Баста / Ноггано',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch266.jpg',
            link: 'https://t.me/nogganobasta',
            language: 'ru-RU'
        },
        {
            id: 267,
            category: 2,
            name: 'Джарахов Эльдар Казанфарович крутой рэпер офигенный тиктокер и вообще очень талантливый человек',
            description: '18+',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch267.jpg',
            link: 'https://t.me/dzharakhov1',
            language: 'ru-RU'
        },
        {
            id: 268,
            category: 2,
            name: 'Светлана Акулова',
            description: 'Генеральный директор ГАУ «Московский зоопарк»',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch268.jpg',
            link: 'https://t.me/svetlanaakulova1',
            language: 'ru-RU'
        },
        {
            id: 269,
            category: 2,
            name: 'КУЗНЕЦОВА',
            description: 'Я теннисистка Светлана Кузнецова, и это мой официальный личный канал — пишу о теннисе, спорте, моей жизни и собаках',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch269.jpg',
            link: 'https://t.me/svetlanakuznetsova27',
            language: 'ru-RU'
        },
        {
            id: 270,
            category: 2,
            name: 'Светлана Антонова',
            description: 'Актриса театра и кино.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch270.jpg',
            link: 'https://t.me/sveta_antonova_official',
            language: 'ru-RU'
        },
        {
            id: 271,
            category: 2,
            name: 'SVETLANA KOROLEVA',
            description: 'БЛОГ ОБ ЭНЕРГИИ И ДВИЖЕНИИ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch271.jpg',
            link: 'https://t.me/svetlana_koroleva_energy',
            language: 'ru-RU'
        },
        {
            id: 272,
            category: 2,
            name: 'Чат с Дарьей Пынзарь',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch272.jpg',
            link: 'https://t.me/DaryaPinzar86',
            language: 'ru-RU'
        },
        {
            id: 273,
            category: 2,
            name: 'Дарья Нелуш | Обществознание',
            description: 'Дарья Нелуш - твой препод по общаге',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch273.jpg',
            link: 'https://t.me/darya_ege',
            language: 'ru-RU'
        },
        {
            id: 274,
            category: 2,
            name: 'Дарья Донцова',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch274.jpg',
            link: 'https://t.me/dontsovaofficial',
            language: 'ru-RU'
        },
        {
            id: 275,
            category: 2,
            name: 'Дарья Повереннова',
            description: 'Российская актриса театра и кино, executive-коуч',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch275.jpg',
            link: 'https://t.me/daryapoverennova',
            language: 'ru-RU'
        },
        {
            id: 276,
            category: 4,
            name: 'Валентина Матвиенко',
            description: 'Председатель Совета Федерации Федерального Собрания Российской Федерации',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch276.jpg',
            link: 'https://t.me/valentinamatvienko',
            language: 'ru-RU'
        },
        {
            id: 277,
            category: 2,
            name: 'Диана Арбенина',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch277.jpg',
            link: 'https://t.me/darbenina',
            language: 'ru-RU'
        },
        {
            id: 278,
            category: 2,
            name: 'Диана Евлаш',
            description: 'Личный дневник о самом важном',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch278.jpg',
            link: 'https://t.me/diana_evlash',
            language: 'ru-RU'
        },
        {
            id: 279,
            category: 2,
            name: 'Diana Mironova',
            description: 'Заслуженный Мастер Спорта РФ Обладательница Кубка Чемпионов BetBoom 2023',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch279.jpg',
            link: 'https://t.me/mironovadi777',
            language: 'ru-RU'
        },
        {
            id: 280,
            category: 2,
            name: 'IVANITSKAYA',
            description: 'Diana Dinama',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch280.jpg',
            link: 'https://t.me/diana_ivanitskaya',
            language: 'ru-RU'
        },
        {
            id: 281,
            category: 2,
            name: 'Илья Соболев Клан Соболева',
            description: 'Официальный канал Ильи Соболева.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch281.jpg',
            link: 'https://t.me/sobolevtelergam',
            language: 'ru-RU'
        },
        {
            id: 282,
            category: 2,
            name: 'Terrence K Williams',
            description: 'Comedian, Actor & Commentator Author of " From The Foster House To The White House "',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch282.jpg',
            link: 'https://t.me/TerrenceKWilliams',
            language: 'en-EN'
        },
        {
            id: 283,
            category: 2,
            name: 'Martina Vismara',
            description: 'Blog',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch283.jpg',
            link: 'https://t.me/ofvismy2',
            language: 'en-EN'
        },
        {
            id: 284,
            category: 4,
            name: 'Jenny Beth Martin',
            description: 'Co-Founder Tea Party Patriots. Tips, Tales, and Thoughts of Peach State Mom of Twins. Christian, Conservative, was Republican now American.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch284.jpg',
            link: 'https://t.me/JennyBethM',
            language: 'en-EN'
        },
        {
            id: 285,
            category: 2,
            name: 'Daniel Bostic',
            description: 'Associate Producer of The Plot Against the President • Former Congressional Staffer • Has-Been Model',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch285.jpg',
            link: 'https://t.me/JennyBethM',
            language: 'en-EN'
        },
        {
            id: 286,
            category: 2,
            name: 'Paul Joseph Watson',
            description: '"Dangerous person"',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch286.jpg',
            link: 'https://t.me/pjwnews',
            language: 'en-EN'
        },
        {
            id: 287,
            category: 2,
            name: 'Ryan Fournier',
            description: 'The official broadcast channel for exclusive content from Ryan Fournier - Founder of Students for Trump / Political Commentator.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch287.jpg',
            link: 'https://t.me/RyanAFournier',
            language: 'en-EN'
        },
        {
            id: 288,
            category: 3,
            name: 'Everscale Community',
            description: 'Everscale Network official international community.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch288.jpg',
            link: 'https://t.me/everscale',
            language: 'en-EN'
        },
        {
            id: 289,
            category: 3,
            name: 'Everscale RU Community',
            description: 'Русскоговорящее сообщество Everscale',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch289.jpg',
            link: 'https://t.me/everscale_rus',
            language: 'ru-RU'
        },
        {
            id: 290,
            category: 3,
            name: 'Everscale News',
            description: 'The Everscale Community Official channel',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch290.jpg',
            link: 'https://t.me/everscale_news',
            language: 'en-EN'
        },
        {
            id: 291,
            category: 2,
            name: 'Michael Lynch Music',
            description: 'Nashville, TN',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch291.jpg',
            link: 'https://t.me/MLMusicNews',
            language: 'en-EN'
        },
        {
            id: 292,
            category: 2,
            name: 'Anthony Rogers',
            description: 'Follow The Anthony Rogers Show',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch292.jpg',
            link: 'https://t.me/betterthanthebeatles',
            language: 'en-EN'
        },
        {
            id: 293,
            category: 2,
            name: 'Nicholas Veniamin',
            description: 'Blog',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch293.jpg',
            link: 'https://t.me/NicholasVeniamin',
            language: 'en-EN'
        },
        {
            id: 294,
            category: 3,
            name: 'Cryptoy ₿',
            description: '#1 Fastest Crypto News',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch294.jpg',
            link: 'https://t.me/Cryptoy',
            language: 'en-EN'
        },
        {
            id: 295,
            category: 3,
            name: 'hypersonic | Crypto & NFT News',
            description: 'the most important crypto news daily.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch295.jpg',
            link: 'https://t.me/hs_cryptonews',
            language: 'en-EN'
        },
        {
            id: 296,
            category: 3,
            name: 'B2C Crypto Calls & News',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch296.jpg',
            link: 'https://t.me/b2cgroupofficial',
            language: 'en-EN'
        },
        {
            id: 297,
            category: 3,
            name: 'Crypto Library News',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch297.jpg',
            link: 'https://t.me/CryptoLibrarys',
            language: 'en-EN'
        },
        {
            id: 298,
            category: 3,
            name: 'Getgems NFT',
            description: 'getgems.io — the Home of NFT collections on The Open Network - an ultra-fast, secure, and truly decentralized blockchain network.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch298.jpg',
            link: 'https://t.me/getgems',
            language: 'en-EN'
        },
        {
            id: 299,
            category: 4,
            name: 'Emmanuel Macron',
            description: 'Président de la République française.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch299.jpg',
            link: 'https://t.me/emmanuelmacron',
            language: 'en-EN'
        },
        {
            id: 300,
            category: 2,
            name: 'PIMENOV.RU',
            description: 'Сергей Пименов, ППК, продюсер. Авторский канал. Музыка, видео, истории.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch300.jpg',
            link: 'https://t.me/pimenov_ru',
            language: 'ru-RU'
        },
        {
            id: 301,
            category: 2,
            name: 'Эрика Парфёнова',
            description: 'Visual geek, video editor and photographer — от съёмок селебрети до нейросетей. А ещё я смешно шучу)',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch301.jpg',
            link: 'https://t.me/erikarederika',
            language: 'ru-RU'
        },
        {
            id: 302,
            category: 2,
            name: 'Эрика Лундмоен | Erika Lundmoen',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch302.jpg',
            link: 'https://t.me/erikalundmoen',
            language: 'ru-RU'
        },
        {
            id: 303,
            category: 5,
            name: 'Газпромбанк Инвестиции',
            description: 'Сервис для инвестиций для новичков и экспертов. Читайте аналитику, слушайте эфиры и выбирайте, во что инвестировать.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch303.jpg',
            link: 'https://t.me/gpb_investments',
            language: 'ru-RU'
        },
        {
            id: 304,
            category: 2,
            name: 'Газпром нефть',
            description: 'Технологический лидер нефтегазового рынка России',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch304.jpg',
            link: 'https://t.me/gazpromneft_official',
            language: 'ru-RU'
        },
        {
            id: 305,
            category: 2,
            name: 'АЗС «Газпромнефть»',
            description: 'Официальный канал АЗС «Газпромнефть»',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch305.jpg',
            link: 'https://t.me/gpnbonus',
            language: 'ru-RU'
        },
        {
            id: 306,
            category: 2,
            name: '«Газпром»-Академия',
            description: 'Официальный telegram-канал «Газпром»-Академии футбольного клуба «Зенит».',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch306.jpg',
            link: 'https://t.me/gazpromacademy',
            language: 'ru-RU'
        },
        {
            id: 307,
            category: 2,
            name: 'ПАО «ЛУКОЙЛ»',
            description: 'ЛУКОЙЛ - международная энергетическая компания',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch307.jpg',
            link: 'https://t.me/lukoilofficial',
            language: 'ru-RU'
        },
        {
            id: 308,
            category: 2,
            name: 'LUKOIL',
            description: 'We are a privately owned international energy company',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch308.jpg',
            link: 'https://t.me/LUKOIL_GLOBAL',
            language: 'en-EN'
        },
        {
            id: 309,
            category: 2,
            name: 'ПАО «НК «Роснефть»',
            description: 'Роснефть является лидером российской нефтяной отрасли и крупнейшим налогоплательщиком',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch309.jpg',
            link: 'https://t.me/rosneftofficial',
            language: 'ru-RU'
        },
        {
            id: 310,
            category: 2,
            name: 'ОСКАРИДЗЕ',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch310.jpg',
            link: 'https://t.me/oskaridzeb',
            language: 'ru-RU'
        },
        {
            id: 311,
            category: 2,
            name: 'Студия Феникс',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch311.jpg',
            link: 'https://t.me/phoenix_animation',
            language: 'ru-RU'
        },
        {
            id: 312,
            category: 2,
            name: '_robertik_._',
            description: 'Рисую для тебя',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch312.jpg',
            link: 'https://t.me/rrobertik',
            language: 'ru-RU'
        },
        {
            id: 313,
            category: 2,
            name: 'Ryan Moore',
            description: 'Songwriter, Composer & Music Producer.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch313.jpg',
            link: 'https://t.me/RyanMoore',
            language: 'en-EN'
        },
        {
            id: 314,
            category: 2,
            name: 'Samuel Leach Updates!',
            description: 'My Whatsapp has been so overrun, i cannot keep up with the thousands of people adding',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch314.jpg',
            link: 'https://t.me/samuelleachupdates',
            language: 'en-EN'
        },
        {
            id: 315,
            category: 5,
            name: 'МТС Банк',
            description: 'Новости МТС Банка без задержки',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch315.jpg',
            link: 'https://t.me/mts_bank_official',
            language: 'ru-RU'
        },
        {
            id: 316,
            category: 5,
            name: 'Банк РНКБ',
            description: 'Экономика',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch316.jpg',
            link: 'https://t.me/rncb_official',
            language: 'ru-RU'
        },
        {
            id: 317,
            category: 5,
            name: 'Фора-Банк',
            description: 'Официальный канал АКБ "ФОРА-БАНК" (АО) в Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch317.jpg',
            link: 'https://t.me/forabank_official',
            language: 'ru-RU'
        },
        {
            id: 318,
            category: 5,
            name: 'Кредит Европа Банк',
            description: 'Все новости, Акции и полезные материалы от Кредит Европа Банка',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch318.jpg',
            link: 'https://t.me/crediteuropebank_official',
            language: 'ru-RU'
        },
        {
            id: 319,
            category: 5,
            name: 'Райффайзен Банк',
            description: 'Официальный аккаунт Райффайзен Банка',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch319.jpg',
            link: 'https://t.me/Raiffeisenbankrus',
            language: 'ru-RU'
        },
        {
            id: 320,
            category: 5,
            name: 'БКС Мир инвестиций',
            description: 'Официальный канал БКС. Новости компании, продуктов и сервисов. Прямая речь экспертов и первых лиц.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch320.jpg',
            link: 'https://t.me/bcs_world_of_investments',
            language: 'ru-RU'
        },
        {
            id: 321,
            category: 8,
            name: 'Секрет фирмы',
            description: 'Максимально несерьёзно о серьёзном — деньгах, бизнесе, экономике и не только',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch321.jpg',
            link: 'https://t.me/businesssecrets',
            language: 'ru-RU'
        },
        {
            id: 322,
            category: 9,
            name: 'Игромания',
            description: 'Официальный канал «Игромании»',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch322.jpg',
            link: 'https://t.me/igromania',
            language: 'ru-RU'
        },
        {
            id: 323,
            category: 9,
            name: 'StopGame.ru - Игровые новости',
            description: 'Официальный новостной канал',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch323.jpg',
            link: 'https://t.me/stopgamenews',
            language: 'ru-RU'
        },
        {
            id: 324,
            category: 9,
            name: 'Навигатор игрового мира',
            description: 'Старейший печатный журнал об играх. "Плохие новости" с Репетуром - каждый вторник!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch324.jpg',
            link: 'https://t.me/nim_ru',
            language: 'ru-RU'
        },
        {
            id: 325,
            category: 1,
            name: 'Новости Microsoft Россия',
            description: 'Новости',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch325.jpg',
            link: 'https://t.me/msrussia',
            language: 'ru-RU'
        },
        {
            id: 326,
            category: 1,
            name: 'AppleInsider.ru #новости',
            description: 'Официальный канал сайта AppleInsider.ru',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch326.jpg',
            link: 'https://t.me/appleinsider',
            language: 'ru-RU'
        },
        {
            id: 327,
            category: 9,
            name: 'CD PROJEKT RED',
            description: 'Официальный Telegram-канал студии CD PROJEKT RED. Мы делаем Cyberpunk 2077, «Ведьмака» и ГВИНТ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch327.jpg',
            link: 'https://t.me/cdpr_official',
            language: 'ru-RU'
        },
        {
            id: 328,
            category: 2,
            name: 'Robokassa news official',
            description: 'Официальный Telegram-канал платёжного сервиса Robokassa.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch328.jpg',
            link: 'https://t.me/robokassa_info',
            language: 'ru-RU'
        },
        {
            id: 329,
            category: 1,
            name: 'Sky News',
            description: 'The best of Sky News breaking news, eyewitness journalism, and video.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch329.jpg',
            link: 'https://t.me/SkyNews',
            language: 'en-EN'
        },
        {
            id: 330,
            category: 2,
            name: 'Dragas Dempa',
            description: 'The Man Made Roots',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch330.jpg',
            link: 'https://t.me/dragasdempa',
            language: 'en-EN'
        },
        {
            id: 331,
            category: 2,
            name: 'Matt',
            description: 'do what makes you happy<3',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch331.jpg',
            link: 'https://t.me/thematt',
            language: 'en-EN'
        },
        {
            id: 332,
            category: 2,
            name: 'Matt Dubiel',
            description: 'Matt Dubiel Feed',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch332.jpg',
            link: 'https://t.me/mattonair',
            language: 'en-EN'
        },
        {
            id: 333,
            category: 2,
            name: 'Matt Crump',
            description: 'Texts & diary entries from your favorite unicorn',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch333.jpg',
            link: 'https://t.me/crump',
            language: 'en-EN'
        },
        {
            id: 334,
            category: 2,
            name: 'David J Harris Jr',
            description: 'Husband. Father. Lover of God & Country. Speaker. Best Selling Author of - Why I Couldn’t Stay Silent - Get it signed from my store!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch334.jpg',
            link: 'https://t.me/DavidJHarrisJr',
            language: 'en-EN'
        },
        {
            id: 335,
            category: 2,
            name: 'Andrew Pope',
            description: 'Official Telegram of singer/songwriter/podcaster Andrew Pope',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch335.jpg',
            link: 'https://t.me/andrewpopemusic',
            language: 'en-EN'
        },
        {
            id: 336,
            category: 2,
            name: 'Jackie Russ',
            description: 'Official Telegram Channel for Jackie Russ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch336.jpg',
            link: 'https://t.me/jackierussofficial',
            language: 'en-EN'
        },
        {
            id: 337,
            category: 3,
            name: 'MEXC Community Channel',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch337.jpg',
            link: 'https://t.me/MEXCofficialNews',
            language: 'en-EN'
        },
        {
            id: 338,
            category: 3,
            name: 'MEXC English (Official)',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch338.jpg',
            link: 'https://t.me/MEXCEnglish',
            language: 'en-EN'
        },
        {
            id: 339,
            category: 3,
            name: 'CoinEx - Making Crypto Trading',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch339.jpg',
            link: 'https://t.me/CoinExOfficialEN',
            language: 'en-EN'
        },
        {
            id: 340,
            category: 3,
            name: 'CoinW Exchange Official English',
            description: 'CoinW staff will never ask your password or transfer money!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch340.jpg',
            link: 'https://t.me/CoinwExchangeEnglish',
            language: 'en-EN'
        },
        {
            id: 341,
            category: 3,
            name: 'CoinEx_Announcement',
            description: 'Stay tuned for CoinEx official updates and news!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch341.jpg',
            link: 'https://t.me/CoinEx_Announcement',
            language: 'en-EN'
        },
        {
            id: 342,
            category: 3,
            name: 'CoinEx Комьюнити',
            description: 'Официальный Telegram чат CoinEx на русском языке.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch342.jpg',
            link: 'https://t.me/CoinEx_Announcement',
            language: 'ru-RU'
        },
        {
            id: 343,
            category: 3,
            name: 'CoinMarketCap Announcements',
            description: 'Welcome to our official updates, news, and content-only channel! To chat with the community',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch343.jpg',
            link: 'https://t.me/CoinMarketCapAnnouncements',
            language: 'en-EN'
        },
        {
            id: 344,
            category: 3,
            name: 'CoinMarketCap English',
            description: 'CoinMarketCap official Telegram group.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch344.jpg',
            link: 'https://t.me/CoinMarketCap',
            language: 'en-EN'
        },
        {
            id: 345,
            category: 3,
            name: 'Cointelegraph',
            description: 'Cointelegraph covers fintech, blockchain and Bitcoin, bringing you the latest news and analyses on the future of money.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch345.jpg',
            link: 'https://t.me/cointelegraph',
            language: 'en-EN'
        },
        {
            id: 346,
            category: 3,
            name: 'CoinGecko',
            description: 'Official CoinGecko Telegram Group',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch346.jpg',
            link: 'https://t.me/coingecko',
            language: 'en-EN'
        },
        {
            id: 347,
            category: 3,
            name: 'A4.finance Announcement',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch347.jpg',
            link: 'https://t.me/a4_finance',
            language: 'en-EN'
        },
        {
            id: 348,
            category: 1,
            name: 'Manchester United - Sun Sport',
            description: 'Get the latest news and updates on Manchester United and the beautiful game straight from The Sun Sport.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch348.jpg',
            link: 'https://t.me/ManchesterUnitedSunSport',
            language: 'en-EN'
        },
        {
            id: 349,
            category: 1,
            name: 'FRANCE 24 English',
            description: 'The French international news channel live 24/7 from Paris',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch349.jpg',
            link: 'https://t.me/France24_en',
            language: 'en-EN'
        },
        {
            id: 350,
            category: 8,
            name: 'The Business Times',
            description: 'South-east Asia`s leading financial news',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch350.jpg',
            link: 'https://t.me/BizTimes',
            language: 'en-EN'
        },
        {
            id: 351,
            category: 3,
            name: 'KCC Official English Channel',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch351.jpg',
            link: 'https://t.me/KCCOfficialChannel',
            language: 'en-EN'
        },
        {
            id: 352,
            category: 2,
            name: 'Яндекс',
            description: 'Технологии будущего и сервисы, которые делают жизнь лучше уже сегодня',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch352.jpg',
            link: 'https://t.me/yandex',
            language: 'ru-RU'
        },
        {
            id: 353,
            category: 2,
            name: 'ТЕХНО: Яндекс про технологии',
            description: 'Канал Яндекса о том, как устроен мир технологий. Разберёмся в потоке технологических новостей.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch353.jpg',
            link: 'https://t.me/techno_yandex',
            language: 'ru-RU'
        },
        {
            id: 354,
            category: 2,
            name: 'Яндекс Музыка',
            description: 'Всё, что вы слушаете: миллионы треков, Моя волна, подкасты и аудиокниги',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch354.jpg',
            link: 'https://t.me/yandexmusic_live',
            language: 'ru-RU'
        },
        {
            id: 355,
            category: 2,
            name: 'Алиса х Около Яндекс Станции',
            description: 'Новости и полезная информация про Яндекс Станции, Умный дом Яндекса и Алису.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch355.jpg',
            link: 'https://t.me/alice_yndx',
            language: 'ru-RU'
        },
        {
            id: 356,
            category: 2,
            name: 'Яндекс Реклама',
            description: 'Рассказываем о новостях и обновлениях сервисов, делимся исследованиями, кейсами специалистов и советами экспертов.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch356.jpg',
            link: 'https://t.me/YANtech',
            language: 'ru-RU'
        },
        {
            id: 357,
            category: 9,
            name: 'Lineage 2 Russia',
            description: 'Официальный канал игры Lineage 2',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch357.jpg',
            link: 'https://t.me/Lineage2Russia',
            language: 'ru-RU'
        },
        {
            id: 358,
            category: 9,
            name: 'Lineage 2 Essence',
            description: 'Официальный канал игры Lineage 2 Essence',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch358.jpg',
            link: 'https://t.me/lineage2essence',
            language: 'ru-RU'
        },
        {
            id: 359,
            category: 9,
            name: 'Lineage 2 Legacy',
            description: 'Официальный канал игры Lineage 2 Legacy',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch359.jpg',
            link: 'https://t.me/Lineage2LegacyRussia',
            language: 'ru-RU'
        },
        {
            id: 360,
            category: 9,
            name: 'AION',
            description: 'Официальный русскоязычный telegram-канал AION.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch360.jpg',
            link: 'https://t.me/AION_RU',
            language: 'ru-RU'
        },
        {
            id: 361,
            category: 9,
            name: 'Perfect World Mobile: Начало',
            description: 'Начните своё путешествие в таинственную вселенную Идеального Мира!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch361.jpg',
            link: 'https://t.me/pwmnews',
            language: 'ru-RU'
        },
        {
            id: 362,
            category: 3,
            name: 'GameFi.org Announcement Channel',
            description: 'Official updates and announcement from GameFi.org team',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch362.jpg',
            link: 'https://t.me/GameFi_OfficialANN',
            language: 'en-EN'
        },
        {
            id: 363,
            category: 4,
            name: 'The American Spectator',
            description: 'Providing the wittiest conservative commentary since 1967',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch363.jpg',
            link: 'https://t.me/AmericanSpectator',
            language: 'en-EN'
        },
        {
            id: 364,
            category: 1,
            name: 'American Military News',
            description: 'Rapid US and global military and geopolitical news.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch364.jpg',
            link: 'https://t.me/americanmilitarynews',
            language: 'en-EN'
        },
        {
            id: 365,
            category: 7,
            name: 'Changi Airport',
            description: 'The official @ChangiAirport Telegram account.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch365.jpg',
            link: 'https://t.me/changiairport',
            language: 'en-EN'
        },
        {
            id: 366,
            category: 1,
            name: 'Love Island - The Sun',
            description: 'Get the latest Love Island gossip before anyone else, straight from The Sun',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch366.jpg',
            link: 'https://t.me/loveisland2021',
            language: 'en-EN'
        },
        {
            id: 367,
            category: 4,
            name: 'Donald Trump Jr',
            description: 'EVP of Development & Acquisitions The Trump Organization, Father, Outdoorsman, In a past life Boardroom Advisor on The Apprentice',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch367.jpg',
            link: 'https://t.me/TrumpJr',
            language: 'en-EN'
        },
        {
            id: 368,
            category: 2,
            name: 'Charlie Kirk',
            description: 'Founder & President: @TPUSA',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch368.jpg',
            link: 'https://t.me/CharlieKirk',
            language: 'en-EN'
        },
        {
            id: 369,
            category: 2,
            name: 'Turning Point USA',
            description: 'Official account of Turning Point USA. Founded by Charlie Kirk',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch369.jpg',
            link: 'https://t.me/TPUSA',
            language: 'en-EN'
        },
        {
            id: 370,
            category: 2,
            name: 'James Smith Bondi Boys’ Club',
            description: 'Stay informed.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch370.jpg',
            link: 'https://t.me/bondiboysclub',
            language: 'en-EN'
        },
        {
            id: 371,
            category: 2,
            name: 'James maslow',
            description: 'Blog',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch371.jpg',
            link: 'https://t.me/jamesmaslowofficial',
            language: 'en-EN'
        },
        {
            id: 372,
            category: 6,
            name: 'Skillbox: образовательная платформа',
            description: 'Официальный канал образовательной платформы Skillbox.ru',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch372.jpg',
            link: 'https://t.me/skillboxru',
            language: 'ru-RU'
        },
        {
            id: 373,
            category: 6,
            name: 'IT-школа Skillfactory',
            description: 'Skillfactory — это не просто курсы и обучение, это практика и опыт, которые пригодятся для будущей работы.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch373.jpg',
            link: 'https://t.me/skillfactory',
            language: 'ru-RU'
        },
        {
            id: 374,
            category: 6,
            name: 'Высшая школа экономики',
            description: 'Официальный канал Высшей школы экономики. Все самое важное о жизни университета, образовании, науке и экспертизе.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch374.jpg',
            link: 'https://t.me/hse_official',
            language: 'ru-RU'
        },
        {
            id: 375,
            category: 6,
            name: 'Фоксфорд | Онлайн-школа',
            description: 'Образование',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch375.jpg',
            link: 'https://t.me/foxford_edu',
            language: 'ru-RU'
        },
        {
            id: 376,
            category: 1,
            name: 'Hindustan Times- Official Channel',
            description: 'The best of HT print and digital specially curated for you',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch376.jpg',
            link: 'https://t.me/hindustantimes',
            language: 'en-EN'
        },
        {
            id: 377,
            category: 2,
            name: 'Dr. Jordan B. Peterson Official Telegram',
            description: 'Official Dr. Jordan B. Peterson Telegram Channel for any updates, podcasts, personal projects, twitter shares, or general news.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch377.jpg',
            link: 'https://t.me/DrJordanPeterson',
            language: 'en-EN'
        },
        {
            id: 378,
            category: 3,
            name: 'Coinsbit CryptoExchange',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch378.jpg',
            link: 'https://t.me/coinsbit_chat',
            language: 'en-EN'
        },
        {
            id: 379,
            category: 3,
            name: 'Trust Wallet - Announcements',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch379.jpg',
            link: 'https://t.me/trust_announcements',
            language: 'en-EN'
        },
        {
            id: 380,
            category: 3,
            name: 'Trust Wallet English',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch380.jpg',
            link: 'https://t.me/trustwallet',
            language: 'en-EN'
        },
        {
            id: 381,
            category: 3,
            name: 'Binance Announcements',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch381.jpg',
            link: 'https://t.me/binance_announcements',
            language: 'en-EN'
        },
        {
            id: 382,
            category: 3,
            name: 'Binance English',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch382.jpg',
            link: 'https://t.me/binance_announcements',
            language: 'en-EN'
        },
        {
            id: 383,
            category: 1,
            name: 'Jovem Pan News',
            description: 'Se inscreva no canal oficial da Jovem Pan News e fique por dentro de todas as notícias',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch383.jpg',
            link: 'https://t.me/oficialjpnews',
            language: 'pt-PT'
        },
        {
            id: 384,
            category: 1,
            name: 'Tecnoblog',
            description: 'Tecnologia que interessa. As melhores notícias do TB estão aqui!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch384.jpg',
            link: 'https://t.me/Tecnoblog',
            language: 'pt-PT'
        },
        {
            id: 385,
            category: 2,
            name: 'Jair M. Bolsonaro 1',
            description: 'Canal Pessoal de Jair Messias Bolsonaro.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch385.jpg',
            link: 'https://t.me/jairbolsonarobrasil',
            language: 'pt-PT'
        },
        {
            id: 386,
            category: 4,
            name: 'Nikolas Ferreira',
            description: 'Dep. Federal mais votado do Brasil e da história de Minas Gerais',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch386.jpg',
            link: 'https://t.me/nikolasferreira',
            language: 'pt-PT'
        },
        {
            id: 387,
            category: 4,
            name: 'Canal do Lula',
            description: 'Política',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch387.jpg',
            link: 'https://t.me/LulanoTelegram',
            language: 'pt-PT'
        },
        {
            id: 388,
            category: 2,
            name: 'Dicas do Telegram',
            description: 'Telegram é sinônimo de liberdade e privacidade, e tem várias funcionalidades fáceis de usar.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch388.jpg',
            link: 'https://t.me/TelegramTipsBR',
            language: 'pt-PT'
        },
        {
            id: 389,
            category: 2,
            name: 'Telegram Brasil',
            description: 'Canal oficial do Telegram, no Telegram, sobre o Telegram (Wow) com detalhes das atualizações e novidades.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch389.jpg',
            link: 'https://t.me/TelegramBR',
            language: 'pt-PT'
        },
        {
            id: 390,
            category: 4,
            name: 'Ciro Gomes Oficial',
            description: 'oi deputado, prefeito, governador e ministro. Viabilizou grandes obras, ajudou a consolidar o Plano Real e nunca foi',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch390.jpg',
            link: 'https://t.me/cirogomes',
            language: 'pt-PT'
        },
        {
            id: 391,
            category: 1,
            name: 'BBC News Brasil',
            description: 'Canal oficial de transmissão de notícias da BBC News Brasil.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch391.jpg',
            link: 'https://t.me/bbcbrasil',
            language: 'pt-PT'
        },
        {
            id: 392,
            category: 2,
            name: 'AliExpress Brasil - OFICIAL',
            description: 'Grupo OFICIAL do AliExpress Brasil no Telegram.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch392.jpg',
            link: 'https://t.me/aliexpressbroficial',
            language: 'pt-PT'
        },
        {
            id: 393,
            category: 1,
            name: 'Sputnik Brasil',
            description: 'Canal oficial da Sputnik Brasil',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch393.jpg',
            link: 'https://t.me/SputnikBrasil',
            language: 'pt-PT'
        },
        {
            id: 394,
            category: 9,
            name: 'Free Fire Brasil Oficial',
            description: 'Free Fire é o jogo mobile mais baixado do mundo em 2020 pelo segundo ano consecutivo, segundo o App Annie!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch394.jpg',
            link: 'https://t.me/frifasbr',
            language: 'pt-PT'
        },
        {
            id: 395,
            category: 1,
            name: 'MBL - Movimento Brasil Livre',
            description: 'Aqui a notícia sai em primeira mão! Participe',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch395.jpg',
            link: 'https://t.me/mblivre',
            language: 'pt-PT'
        },
        {
            id: 396,
            category: 2,
            name: 'Apib Oficial',
            description: 'Canal para compartilhamento dos conteúdos da Articulação dos Povos Indígenas do Brasil no Telegram',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch396.jpg',
            link: 'https://t.me/apiboficial',
            language: 'pt-PT'
        },
        {
            id: 397,
            category: 2,
            name: 'Site Mundo Negro',
            description: 'O melhor conteúdo sobre negros do Brasil!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch397.jpg',
            link: 'https://t.me/SiteMundoNegro',
            language: 'pt-PT'
        },
        {
            id: 398,
            category: 2,
            name: 'Shopee Brasil [Oficial]',
            description: 'Sua influencer de comprinhas favoritas',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch398.jpg',
            link: 'https://t.me/shopee_br',
            language: 'pt-PT'
        },
        {
            id: 399,
            category: 4,
            name: 'CARLOS BOLSONARO',
            description: 'Canal Pessoal do Vereador Carlos Bolsonaro',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch399.jpg',
            link: 'https://t.me/bolsonarocarlos',
            language: 'pt-PT'
        },
        {
            id: 400,
            category: 4,
            name: 'FLÁVIO BOLSONARO',
            description: 'Canal oficial do senador Flávio Bolsonaro - RJ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch400.jpg',
            link: 'https://t.me/senadorflaviobolsonaro',
            language: 'pt-PT'
        },
        {
            id: 401,
            category: 4,
            name: 'Governo do Estado de São Paul',
            description: 'Canal oficial de informações do Governo do Estado de São Paulo. Acesse:',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch401.jpg',
            link: 'https://t.me/governosp',
            language: 'pt-PT'
        },
        {
            id: 402,
            category: 4,
            name: 'Imprensa - Governo do Estad',
            description: 'Canal oficial do Governo do Estado de São Paulo',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch402.jpg',
            link: 'https://t.me/imprensagovsp',
            language: 'pt-PT'
        },
        {
            id: 403,
            category: 2,
            name: 'GovernoMA',
            description: 'Blogues',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch403.jpg',
            link: 'https://t.me/GovernoMA',
            language: 'pt-PT'
        },
        {
            id: 404,
            category: 2,
            name: 'Sebrae Rio de Janeiro',
            description: 'Canal Oficial do Sebrae Rio.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch404.jpg',
            link: 'https://t.me/sebraerj',
            language: 'pt-PT'
        },
        {
            id: 405,
            category: 2,
            name: 'Ministério da Saúde',
            description: 'Canal oficial para informações, serviços e esclarecimentos do nosso dia a dia.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch405.jpg',
            link: 'https://t.me/MinisterioDaSaudeBR',
            language: 'pt-PT'
        },
        {
            id: 406,
            category: 4,
            name: 'Ministério da Defesa',
            description: 'O Ministério da Defesa é o órgão do Governo Federal que exerce a direção superior das',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch406.jpg',
            link: 'https://t.me/MinisteriodaDefesaOficial',
            language: 'pt-PT'
        },
        {
            id: 407,
            category: 4,
            name: 'Ministério da Justiça e Segurança',
            description: 'Canal de informações oficial do Ministério da Justiça e Segurança Pública.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch407.jpg',
            link: 'https://t.me/justicagovbr',
            language: 'pt-PT'
        },
        {
            id: 408,
            category: 4,
            name: 'Ministério da Integração e do Desenvolvimento',
            description: 'Órgão responsável por obras de segurança hídrica, defesa civil, desenvolvimento urbano e regional.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch408.jpg',
            link: 'https://t.me/midregional',
            language: 'pt-PT'
        },
        {
            id: 409,
            category: 4,
            name: 'Câmara dos Deputados',
            description: 'Bem-vindo ao canal de informações da Câmara dos Deputados!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch409.jpg',
            link: 'https://t.me/CamaradosDeputados',
            language: 'pt-PT'
        },
        {
            id: 410,
            category: 4,
            name: 'Deputado Anderson Moraes',
            description: 'Canal do Deputado Estadual Anderson Moraes Patriota',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch410.jpg',
            link: 'https://t.me/depandersonmoraes',
            language: 'pt-PT'
        },
        {
            id: 411,
            category: 4,
            name: 'Deputado Junio Amaral',
            description: 'Olá, seja bem vindo ao canal do Deputado Junio Amaral.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch411.jpg',
            link: 'https://t.me/junioamaral',
            language: 'pt-PT'
        },
        {
            id: 412,
            category: 4,
            name: 'Deputado Estadual Sebastião Santos',
            description: 'Política',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch412.jpg',
            link: 'https://t.me/deputadosebastiaosantos',
            language: 'pt-PT'
        },
        {
            id: 413,
            category: 4,
            name: 'Deputado Paulo Trabalho',
            description: 'Olá, seja bem vindo ao canal do Deputado Paulo Trabalho.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch413.jpg',
            link: 'https://t.me/paulo_trabalho',
            language: 'pt-PT'
        },
        {
            id: 414,
            category: 3,
            name: 'The Open Network',
            description: 'TON - The Open Network - Key network updates, network-wide proposals and votings',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch414.jpg',
            link: 'https://t.me/tonblockchain',
            language: 'en-EN'
        },
        {
            id: 415,
            category: 3,
            name: 'TON Diamonds NFT',
            description: 'Marketplace for digital artists and high-quality collections. The first NFT on TON with privileges for holders.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch415.jpg',
            link: 'https://t.me/tondiamonds',
            language: 'en-EN'
        },
        {
            id: 416,
            category: 3,
            name: 'TON Diamonds NFT RUS',
            description: 'Маркетплейс для качественных коллекций и цифровых художников. Первый NFT на TON с привилегиями для владельцев.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch416.jpg',
            link: 'https://t.me/tondiamonds_rus',
            language: 'ru-RU'
        },
        {
            id: 417,
            category: 3,
            name: 'TON Status',
            description: 'Technical notifications and up-to-date requests for action for TON validators, developers and integrators.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch417.jpg',
            link: 'https://t.me/tonstatus',
            language: 'en-EN'
        },
        {
            id: 418,
            category: 2,
            name: 'TecMundo',
            description: 'O seu lugar no mundo da tecnologia',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch418.jpg',
            link: 'https://t.me/tecmundooficial',
            language: 'pt-PT'
        },
        {
            id: 419,
            category: 2,
            name: 'Dream big, girl',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch419.jpg',
            link: 'https://t.me/alinaxdream',
            language: 'ru-RU'
        },
        {
            id: 420,
            category: 2,
            name: 'Tedros Adhanom Ghebreyesus',
            description: 'Director-General of the World Health Organization',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch420.jpg',
            link: 'https://t.me/DrTedros',
            language: 'en-EN'
        },
        {
            id: 421,
            category: 3,
            name: 'Juancito Gems',
            description: 'CEO Marketing manager & Community Builder.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch421.jpg',
            link: 'https://t.me/JuancitoPayaGems',
            language: 'en-EN'
        },
        {
            id: 422,
            category: 2,
            name: 'G.daal',
            description: 'Blogs',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch422.jpg',
            link: 'https://t.me/Gdaaltelegram',
            language: 'en-EN'
        },
        {
            id: 423,
            category: 3,
            name: 'Gate.io News',
            description: 'Official activities, events and hot news channel of Gate.io – your gateway to cryptocurrency',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch423.jpg',
            link: 'https://t.me/GateioOfficialNews',
            language: 'en-EN'
        },
        {
            id: 424,
            category: 2,
            name: 'Victoria Matosa',
            description: 'Blogues',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch424.jpg',
            link: 'https://t.me/euvictoriamatosa',
            language: 'pt-PT'
        },
        {
            id: 425,
            category: 3,
            name: 'STEPN Official English Group',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch425.jpg',
            link: 'https://t.me/STEPNofficial',
            language: 'en-EN'
        },
        {
            id: 426,
            category: 4,
            name: 'MoD Russia',
            description: 'Ministry of Defence of the Russian Federation',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch426.jpg',
            link: 'https://t.me/mod_russia_en',
            language: 'en-EN'
        },
        {
            id: 427,
            category: 2,
            name: 'Empiricus',
            description: 'Blogues',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch427.jpg',
            link: 'https://t.me/empiricustelegram',
            language: 'pt-PT'
        },
        {
            id: 428,
            category: 4,
            name: 'Tribunal Superior Eleitoral',
            description: 'Canal oficial do TSE no Telegram',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch428.jpg',
            link: 'https://t.me/tsejus',
            language: 'pt-PT'
        },
        {
            id: 429,
            category: 2,
            name: 'Eva Elfie',
            description: 'My personal blog',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch429.jpg',
            link: 'https://t.me/evaelfie_life',
            language: 'ru-RU'
        },
        {
            id: 430,
            category: 2,
            name: 'Ekaterina Enokaeva',
            description: 'Блог',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch430.jpg',
            link: 'https://t.me/Eenokaeva',
            language: 'ru-RU'
        },
        {
            id: 431,
            category: 2,
            name: 'EKATERINA ZUEVA',
            description: 'Official telegram channel',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch431.jpg',
            link: 'https://t.me/zuuevalife',
            language: 'en-EN'
        },
        {
            id: 432,
            category: 5,
            name: 'ECONOMISTA SINCERO',
            description: 'Grupo criado para discutir sobre os vídeos e assuntos relacionados com o Canal do YouTube do Economista Sincero',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch432.jpg',
            link: 'https://t.me/economistasincero',
            language: 'pt-PT'
        },
        {
            id: 433,
            category: 4,
            name: 'Canal do DN',
            description: 'Canal de notícias sobre  o deputado federal Domingos Neto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch433.jpg',
            link: 'https://t.me/dnnoticias',
            language: 'pt-PT'
        },
        {
            id: 434,
            category: 1,
            name: 'CTV News',
            description: 'Breaking news and analysis on the Israel-Gaza war, Russia-Ukraine war, news from China, the U.S. and more.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch434.jpg',
            link: 'https://t.me/ctvnewstelegram',
            language: 'en-EN'
        },
        {
            id: 435,
            category: 2,
            name: 'Телеканал СТС',
            description: 'ПЕРВЫЙ РАЗВЛЕКАТЕЛЬНЫЙ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch435.jpg',
            link: 'https://t.me/channel_ctc',
            language: 'ru-RU'
        },
        {
            id: 436,
            category: 2,
            name: 'Christianity Today',
            description: 'Christianity Today`s official Telegram channel. Thoughtful.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch436.jpg',
            link: 'https://t.me/ctmagazine',
            language: 'en-EN'
        },
        {
            id: 437,
            category: 3,
            name: 'NEAR Protocol Official',
            description: 'NEAR is an open source platform that enables creators, communities, and financial markets to drive a more open',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch437.jpg',
            link: 'https://t.me/cryptonear',
            language: 'en-EN'
        },
        {
            id: 438,
            category: 3,
            name: 'Crypto Daily™',
            description: 'Crypto Daily™ is the leading crypto and blockchain news site that ‘bridges’ the gap between companies.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch438.jpg',
            link: 'https://t.me/officialcryptodaily',
            language: 'en-EN'
        },
        {
            id: 439,
            category: 2,
            name: 'Sarkari Result SarkariResult.Com',
            description: 'Official Telegram Channel by Sarkari Result',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch439.jpg',
            link: 'https://t.me/sarkari',
            language: 'en-EN'
        },
        {
            id: 440,
            category: 3,
            name: 'Bridge Oracle Group',
            description: 'Crypto',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch440.jpg',
            link: 'https://t.me/BridgeOracle',
            language: 'en-EN'
        },
        {
            id: 441,
            category: 3,
            name: 'CMTrading Official',
            description: 'Your Local International Broker',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch441.jpg',
            link: 'https://t.me/cmtradingfx',
            language: 'en-EN'
        },
        {
            id: 442,
            category: 2,
            name: 'Comedy Club | Камеди клаб',
            description: 'Comedy Club Официальный телеграм-канал',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch442.jpg',
            link: 'https://t.me/comedyclubru',
            language: 'ru-RU'
        },
        {
            id: 443,
            category: 9,
            name: 'Citizen Conflict NEWS',
            description: 'Citizen Conflict is a F2P MMO shooter on BNB Smart Chain, introducing a cyberpunk',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch443.jpg',
            link: 'https://t.me/citizenconflictnews',
            language: 'en-EN'
        },
        {
            id: 444,
            category: 3,
            name: 'Inery Official Announcements',
            description: 'Inery is a Decentralized Data Management System and Blockchain Solution.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch444.jpg',
            link: 'https://t.me/inery_channel',
            language: 'en-EN'
        },
        {
            id: 445,
            category: 3,
            name: 'Inery Official Announcements',
            description: 'CEEK Metaverse Community. We`re here to answer your questions and keep you updated',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch445.jpg',
            link: 'https://t.me/ceekmetaverse',
            language: 'en-EN'
        },
        {
            id: 446,
            category: 2,
            name: 'BNG - Bloque Nacionalista Galego',
            description: 'Canle oficial do Bloque Nacionalista Galego en Telegram. Para que coñezas o que facemos por Galiza e por ti.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch446.jpg',
            link: 'https://t.me/bloquenacionalistagalego',
            language: 'pt-PT'
        },
        {
            id: 447,
            category: 3,
            name: 'BNB Chain Community',
            description: 'Beware of scammers, admins will NEVER contact you via DM / PM.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch447.jpg',
            link: 'https://t.me/BNBchaincommunity',
            language: 'en-EN'
        },
        {
            id: 448,
            category: 1,
            name: 'BNO News',
            description: 'Breaking news and developing stories from the U.S. and around the world.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch448.jpg',
            link: 'https://t.me/bnonews',
            language: 'en-EN'
        },
        {
            id: 449,
            category: 1,
            name: 'BM&C News',
            description: 'As principais notícias do mercado financeiro você encontra no portal',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch449.jpg',
            link: 'https://t.me/bmcnews',
            language: 'pt-PT'
        },
        {
            id: 450,
            category: 3,
            name: 'Akash Network',
            description: 'Infrastructure that powers Web3. Akash Network is a decentralized peer-to-peer marketplace for cloud compute',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch450.jpg',
            link: 'https://t.me/AkashNW',
            language: 'en-EN'
        },
        {
            id: 451,
            category: 2,
            name: 'Rafael Aires',
            description: 'Blogues',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch451.jpg',
            link: 'https://t.me/RafaelAires',
            language: 'pt-PT'
        },
        {
            id: 452,
            category: 3,
            name: 'Bybit South Africa',
            description: 'Bybit is a cryptocurrency trading platform that caters to the needs of all types of traders.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch452.jpg',
            link: 'https://t.me/BybitSouthAfrica',
            language: 'en-EN'
        },
        {
            id: 453,
            category: 2,
            name: 'American Center Tashkent',
            description: 'Welcome to American Center Tashkent Channel!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch453.jpg',
            link: 'https://t.me/ACTashkent',
            language: 'en-EN'
        },
        {
            id: 454,
            category: 2,
            name: 'Administradores',
            description: 'Este é o canal oficial do Administradores. Junte-se a nós e receba todos os dias conteúdos sobre Carreira e Negócios!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch454.jpg',
            link: 'https://t.me/portaladministradores',
            language: 'pt-PT'
        },
        {
            id: 455,
            category: 2,
            name: 'Gov.sg-WSG Jobs & Career Advice',
            description: 'Find the latest full time and part time jobs in Singapore, explore upcoming career events, get career tips, and more!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch455.jpg',
            link: 'https://t.me/WSGJobs_CareerAdvice',
            language: 'en-EN'
        },
        {
            id: 456,
            category: 2,
            name: 'Central de Fãs Jonas Esticado',
            description: 'Canal Oficial da Central De Fãs JE',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch456.jpg',
            link: 'https://t.me/CentraldeFasJE',
            language: 'pt-PT'
        },
        {
            id: 457,
            category: 2,
            name: 'Arjuna Harjai',
            description: 'Musician • Youtuber',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch457.jpg',
            link: 'https://t.me/arjunaharjai',
            language: 'en-EN'
        },
        {
            id: 458,
            category: 1,
            name: 'Meia Hora',
            description: 'O jornal mais lido do Rio de Janeiro!',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch458.jpg',
            link: 'https://t.me/jornalmeiahora',
            language: 'pt-PT'
        },
        {
            id: 459,
            category: 4,
            name: 'Rusia en España',
            description: 'Cuenta oficial de la Embajada de Rusia en España',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch459.jpg',
            link: 'https://t.me/EmbajadaRusaEs',
            language: 'es-ES'
        },
        {
            id: 460,
            category: 4,
            name: 'Embajada de Rusia en Mexico',
            description: 'La cuenta oficial de la Embajada de Rusia en México',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch460.jpg',
            link: 'https://t.me/embrusiamexico',
            language: 'es-ES'
        },
        {
            id: 461,
            category: 3,
            name: 'KuCoin en Español',
            description: 'Grupo oficial de KuCoin en Español - un intercambio global de criptomonedas.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch461.jpg',
            link: 'https://t.me/KucoinSpanish',
            language: 'es-ES'
        },
        {
            id: 462,
            category: 2,
            name: 'Fede Hidalgo - Ecommerce',
            description: 'En este canal os ensañaré lo que hago cada día para obtener ventas en internet.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch462.jpg',
            link: 'https://t.me/fedmkt',
            language: 'es-ES'
        },
        {
            id: 463,
            category: 2,
            name: 'DJ GIAN',
            description: 'Melómano, Audiófilo y DJ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch463.jpg',
            link: 'https://t.me/djgianmixes',
            language: 'es-ES'
        },
        {
            id: 464,
            category: 2,
            name: 'Culturizando',
            description: 'Alimenta tu Mente',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch464.jpg',
            link: 'https://t.me/culturizandoINFO',
            language: 'es-ES'
        },
        {
            id: 465,
            category: 2,
            name: 'Autosanación con Ricardo Ponce',
            description: 'Canal Oficial de Ricardo Ponce',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch465.jpg',
            link: 'https://t.me/ricardoponce1',
            language: 'es-ES'
        },
        {
            id: 466,
            category: 3,
            name: 'Binance Argentina Oficial',
            description: 'Bienvenidos al Telegram oficial de Binance Argentina',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch466.jpg',
            link: 'https://t.me/BinanceArgentinaOficial',
            language: 'es-ES'
        },
        {
            id: 467,
            category: 2,
            name: 'Adrián Sáenz - Canal de Telegra',
            description: 'Blogs',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch467.jpg',
            link: 'https://t.me/adriansaenzc',
            language: 'es-ES'
        },
        {
            id: 468,
            category: 7,
            name: 'Holidayguru - Ofertas de viajes',
            description: 'Las mejores ofertas e info sobre viajes',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch468.jpg',
            link: 'https://t.me/holidayguruES',
            language: 'es-ES'
        },
        {
            id: 469,
            category: 1,
            name: 'EL PAÍS América',
            description: 'Contamos un continente al mundo y el mundo a un continente.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch469.jpg',
            link: 'https://t.me/elpaisamerica',
            language: 'es-ES'
        },
        {
            id: 470,
            category: 1,
            name: 'EL PAÍS América Colombia',
            description: 'EL PAÍS América Colombia ofrece noticias de última hora y toda la actualidad nacional',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch470.jpg',
            link: 'https://t.me/elpaisamericacolombia',
            language: 'es-ES'
        },
        {
            id: 471,
            category: 4,
            name: 'Carlos Eduardo Caicedo',
            description: 'Gobernador del Magdalena. Candidato presidencial de Colombia 2018',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch471.jpg',
            link: 'https://t.me/Carlosecaicedo',
            language: 'es-ES'
        },
        {
            id: 472,
            category: 6,
            name: 'Universidad Nacional de Asunción',
            description: 'Canal Oficial de la Universidad Nacional de Asunción. Vitam Impendere Vero',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch472.jpg',
            link: 'https://t.me/una_py',
            language: 'es-ES'
        },
        {
            id: 473,
            category: 2,
            name: 'Dale Azul y Oro',
            description: 'Dale Azul y Oro es un espacio no oficial. Sitio web creado por la afición puma.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch473.jpg',
            link: 'https://t.me/daleazulyoro',
            language: 'es-ES'
        },
        {
            id: 474,
            category: 1,
            name: 'EL PAÍS México',
            description: 'Contamos el mundo a un país y un país al mundo',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch474.jpg',
            link: 'https://t.me/elpaismexico',
            language: 'es-ES'
        },
        {
            id: 475,
            category: 1,
            name: 'Adrián Berazaín -Oficial',
            description: 'Por este canal podemos estar conectados y mantenerte al tanto de mi carrera musical.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch475.jpg',
            link: 'https://t.me/BerazainOfficial',
            language: 'es-ES'
        },
        {
            id: 476,
            category: 3,
            name: 'TON Community',
            description: 'The Open Network (TON) está poniendo cripto en todos los bolsillos. Mediante la construcción de un ecosistema Web3 dentro de Telegram',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch476.jpg',
            link: 'https://t.me/toncoin_es',
            language: 'es-ES'
        },
        {
            id: 477,
            category: 2,
            name: 'Abu Dhabi Sports Council',
            description: 'Abu Dhabi an international capital for sport practicing, competitiveness & international events.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch477.jpg',
            link: 'https://t.me/abudhabisc',
            language: 'ar-AR'
        },
        {
            id: 478,
            category: 3,
            name: 'Binance Arabic',
            description: 'لمجموعة العربية الرسمية لمنصة بينانس لمنطقة',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch478.jpg',
            link: 'https://t.me/BinanceArabic',
            language: 'ar-AR'
        },
        {
            id: 479,
            category: 2,
            name: 'أنس الحاج - Anas Elhadj',
            description: 'Journalist',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch479.jpg',
            link: 'https://t.me/anl7j',
            language: 'ar-AR'
        },
        {
            id: 480,
            category: 1,
            name: 'أنس الحاج - Anas Elhadj',
            description: 'شبكة أجيال - AJYAL',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch480.jpg',
            link: 'https://t.me/ajyalfm',
            language: 'ar-AR'
        },
        {
            id: 481,
            category: 2,
            name: 'قناة القارئ أحمد النفيس',
            description: 'قناة تهتم بنشر تلاوات ومنشورات القارئ أحمد النفيس',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch481.jpg',
            link: 'https://t.me/ahmad_alnufais',
            language: 'ar-AR'
        },
        {
            id: 482,
            category: 2,
            name: 'عالم حواء',
            description: 'قناة عالم حواء الموثقه الخاصة بموقع عالم حواء الرسمي',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch482.jpg',
            link: 'https://t.me/hawaaworldcom',
            language: 'ar-AR'
        },
        {
            id: 483,
            category: 1,
            name: 'تركيا بالعربي',
            description: 'قناة موقع تركيا بالعربي للاخبار',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch483.jpg',
            link: 'https://t.me/ArabTurkeycom',
            language: 'ar-AR'
        },
        {
            id: 484,
            category: 2,
            name: 'الرسالة',
            description: 'لمتابعة منصات "مؤسسة الرسالة للإعلام" الرسمية',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch484.jpg',
            link: 'https://t.me/alresalahpress',
            language: 'ar-AR'
        },
        {
            id: 485,
            category: 2,
            name: 'إذاعة صوت القدس',
            description: 'إذاعة صوت القدس صوت حر يواكب الأحداث المتعلقة',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch485.jpg',
            link: 'https://t.me/qudsradiofm1',
            language: 'ar-AR'
        },
        {
            id: 486,
            category: 2,
            name: 'عمر علي العيساوي omar alisawey',
            description: 'ناشط  وفنان عراقي . حب للناس ما تحب لنفسك . سامح ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch486.jpg',
            link: 'https://t.me/omarAlisawey',
            language: 'ar-AR'
        },
        {
            id: 487,
            category: 2,
            name: 'POWR ARMY',
            description: 'قناة POWR ESPORTS الرسمية على التيلقرام ، تجمع',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch487.jpg',
            link: 'https://t.me/POWRARMY',
            language: 'ar-AR'
        },
        {
            id: 488,
            category: 2,
            name: '365Scores Arabic',
            description: 'القناة الرسمية لتطبيق',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch488.jpg',
            link: 'https://t.me/t365Scores',
            language: 'ar-AR'
        },
        {
            id: 489,
            category: 1,
            name: 'قناة الرابعة - Al Rabiaa TV',
            description: 'يمثل هذا الاسم السلطة الرابعة، وهو مصطلح يُطلق عموماً ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch489.jpg',
            link: 'https://t.me/alrabiaatv',
            language: 'ar-AR'
        },
        {
            id: 490,
            category: 3,
            name: 'KuCoin News Arabic',
            description: 'بوابتك ل تداول البيتكوين و الايثريوم و',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch490.jpg',
            link: 'https://t.me/kucoin_arabic',
            language: 'ar-AR'
        },
        {
            id: 491,
            category: 1,
            name: 'سبوتنيك عربي | Sputnik Arabic',
            description: 'القناة الرسمية لوكالة الأنباء والإذاعة الدولية "سبوتنيك',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch491.jpg',
            link: 'https://t.me/Sputnik_Arabic',
            language: 'ar-AR'
        },
        {
            id: 492,
            category: 1,
            name: 'RT Arabic',
            description: 'اخر اخبار العالم العربي. آراء المختصين. تقارير من موقع',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch492.jpg',
            link: 'https://t.me/rtarabictelegram',
            language: 'ar-AR'
        },
        {
            id: 493,
            category: 2,
            name: 'exness.arabic',
            description: 'قناة تيليجرام الرسمية باللغة العربية لشركة Exness',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch493.jpg',
            link: 'https://t.me/exnessAR',
            language: 'ar-AR'
        },
        {
            id: 494,
            category: 3,
            name: 'KuCoin Arabic Community',
            description: 'بوابتك ل تداول البيتكوين و الايثريوم و ',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch494.jpg',
            link: 'https://t.me/kucoinAR',
            language: 'ar-AR'
        },
        {
            id: 495,
            category: 3,
            name: 'Bybit Arabic',
            description: 'الجروب الرسمي العربي لباي بيت Bybit علي التيليجرام.',
            verify: true,
            avatar: 'https://897821.selcdn.ru/channels/ch495.jpg',
            link: 'https://t.me/BybitArabic',
            language: 'ar-AR'
        },
    ],
};



const homeReducer = (state = initState, action) => {

    switch(action.type) {
        default:
            return state;
    }
}

export default homeReducer;
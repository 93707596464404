import i18n from "i18next";
import { initReactI18next } from "react-i18next";


let setLanguage = 'en-EN';


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({

        resources: {
            ru: {
                translation: {
                    locale: "ru-RU",

                    resultsTitle: "Ничего не найдено",
                    resultsDescription: "Результаты отсутствуют. Попробуйте заново.",
                    open: "Открыть",
                    title: "Исследуйте сообщества",
                    search: "Поиск",
                    telegram: "Telegram"
                }
            },
            en: {
                translation: {
                    locale: "en-EN",

                    resultsTitle: "No Results",
                    resultsDescription: "There were no results. Try a new search.",
                    open: "Open",
                    title: "Explore communities inside",
                    search: "Search",
                    telegram: "Telegram"
                }
            },
            pt: {
                translation: {
                    locale: "pt-PT",

                    resultsTitle: "Sem resultados",
                    resultsDescription: "Sem resultados. Tente novamente.",
                    open: "Revelar",
                    title: "Explorar as comunidades no",
                    search: "Pesquisar",
                    telegram: "Telegram"
                }
            },
            es: {
                translation: {
                    locale: "es-ES",

                    resultsTitle: "No se ha encontrado nada",
                    resultsDescription: "No hay resultados. Vuelve a intentarlo.",
                    open: "Revelar",
                    title: "Explorar las comunidades",
                    search: "Buscar en",
                    telegram: "Telegram"
                }
            },
            ar: {
                translation: {
                    locale: "ar-AR",

                    resultsTitle: "لا يوجد شيء",
                    resultsDescription: "النتائج غير موجودة حاول مرة أخرى.",
                    open: "فتح",
                    title: "استكشاف المجتمعات",
                    search: "البحث",
                    telegram: "تلغرام"
                }
            }

        },
        lng: setLanguage, // Язык по умолчанию
        fallbackLng: 'ru-RU', // Язык, который будет использоваться, если нет перевода для текущего языка
        interpolation: {
        escapeValue: false // Не экранировать специальные символы
        }
    });



import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NotFound = ({

}) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="content">
                <div className="mid">
                    <div className="empty">
                        <div className="empty-title">
                            <span>404 This page could not be found.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

let mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, {
    
})(NotFound);

import { useTranslation } from 'react-i18next';

const CategoryItem = ({
    id,
    en,
    ru,
    pt,
    es,
    ar,
    setActiveTab,
    activeTab,
}) => {

    let setTab = () => {
        setActiveTab(id);
    }

    const { t, i18n } = useTranslation();

    return (
        <div className={activeTab === id ? "categories-button active" : "categories-button"} onClick={setTab} id={id}>
            {t('locale') === 'ru-RU' && [<span key={id}>{ru}</span>]}
            {t('locale') === 'en-EN' && [<span key={id}>{en}</span>]}
            {t('locale') === 'pt-PT' && [<span key={id}>{pt}</span>]}
            {t('locale') === 'es-ES' && [<span key={id}>{es}</span>]}
            {t('locale') === 'ar-AR' && [<span key={id}>{ar}</span>]}
        </div>
    );
}

export default CategoryItem;
import {combineReducers, legacy_createStore} from 'redux';
import homeReducer from './reducers/homeReducer';



let reducers = combineReducers({
    home:homeReducer,
})

let store = legacy_createStore(
    reducers,   
);

window.store = store;

export default store






import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import Verify from '../../assets/images/verify.svg';

const CatalogItem = ({
    name,
    description,
    verify,
    avatar,
    link,
    id
}) => {

    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    function handleImageLoad() {
        setIsLoading(false);
    }

    return (
        <div className="catalog-item" id={id}>
            <div className="catalog-avatar">
                <div className="catalog-icon">
                    {isLoading && (
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 200 200"
                            backgroundColor='var(--tg-theme-section-bg-color)'
                            foregroundColor='var(--tg-theme-secondary-bg-color)'
                        >
                            <rect x="0" y="0" rx="5" ry="5" width="200" height="200" />
                        </ContentLoader>
                    )}
                    <img src={avatar} onLoad={handleImageLoad} style={{ display: isLoading ? 'none' : 'block'}} alt={name}/>
                </div>
                {verify && (
                    <div className="catalog-verify" style={{ display: isLoading ? 'none' : 'block'}}>
                        <img src={Verify} alt={name} />
                    </div>
                )}
            </div>
            <div className="catalog-content">
                <div className="catalog-title">
                    <span>{name.length > 30 ? name.slice(0, 30) + '...' : name}</span>
                </div>
                <div className="catalog-description">
                    <span>{description}</span>
                </div>
            </div>
            <div className="catalog-button">
                <a href={link} target="_blank" rel="noreferrer" className="button">
                    <span>{t('open')}</span>
                </a>
            </div>
        </div>
    );
}

export default CatalogItem;
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/header';
import CatalogItem from '../../components/catalog';
import CategoryItem from '../../components/category';
import Lottie from "lottie-react";
import DuckAnimation from "../../animations/duck.json";
import { useTranslation } from 'react-i18next';
import CatalogAdv from '../../components/adv';

const Home = ({
    channelList,
    categoriesList,
    pageRef,
}) => {

    const [activeTab, setActiveTab] = useState(0);
    const [searchText, setSearchText] = useState('');

    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
        pageRef.current.scrollTo({ 
            top: 0, behavior: 'smooth'
        });
    }

    const categoryElements = categoriesList.map(category => 
        <CategoryItem
            key={category.id}
            id={category.id}
            en={category.en}
            ru={category.ru}
            pt={category.pt}
            es={category.es}
            ar={category.ar}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
        />
    );

    const catalogElements = categoriesList.map(category => {
        let filteredChannels;

        if (activeTab === 0) {
            filteredChannels = channelList;
        } else {
            filteredChannels = channelList.filter(channel => channel.category === category.id);
        }

        filteredChannels = filteredChannels.filter(channel => channel.name.toLowerCase().includes(searchText.toLowerCase()) && channel.language === locale); 

        filteredChannels.sort((a, b) => {

            const ruPattern = /^[а-яА-Я]/;

            if (ruPattern.test(a.name) && !ruPattern.test(b.name)) {
                return -1;
            } else if (!ruPattern.test(a.name) && ruPattern.test(b.name)) {
                return 1;
            } else {
                return a.name.localeCompare(b.name, undefined, {numeric: true});
            }
            
        }); 
        
        return (
            <div key={category.id} className="catalog">
                {filteredChannels.map(channel => 
                    <CatalogItem
                        id={channel.id}
                        key={channel.id}
                        category={channel.category}
                        name={channel.name}
                        description={channel.description}
                        verify={channel.verify}
                        avatar={channel.avatar}
                        link={channel.link}
                        language={channel.language}
                    />
                )}
            </div>
        );
    });

    return (
        <>
            <Header 
                searchText={searchText}
                setSearchText={setSearchText}
            />

            <div className="content">
                <div className="mid">
                    <div className="title">
                        <h1>{t('title')} <span className="title-gradient">{t('telegram')}</span></h1>
                    </div>
                    <div className="categories">
                        <div className="categories-items">
                            {categoryElements}
                        </div>
                    </div>
                    {catalogElements[activeTab].props.children.length === 0 ? (
                        <div className="results">
                            <div className="results-animation">
                                <Lottie animationData={DuckAnimation} loop={true} />
                            </div>
                            <div className="results-title">
                                <span>{t('resultsTitle')}</span>
                            </div>
                            <div className="results-description">
                                <span>{t('resultsDescription')}</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {catalogElements[activeTab]}
                        </>
                    )} 
                </div>
            </div>
            <div className="footer">
                <div className="mid">
                    <div className="footer-items">
                        <div className="footer-item">
                            <div className="footer-language">
                                <div className={t('locale') === 'en-EN' ? "footer-language-button active" : "footer-language-button"} onClick={() => changeLanguage('en-EN')}>
                                    <span>EN</span>
                                </div>
                                <div className={t('locale') === 'ru-RU' ? "footer-language-button active" : "footer-language-button"} onClick={() => changeLanguage('ru-RU')}>
                                    <span>RU</span>
                                </div>
                                <div className={t('locale') === 'pt-PT' ? "footer-language-button active" : "footer-language-button"} onClick={() => changeLanguage('pt-PT')}>
                                    <span>PT</span>
                                </div>
                                <div className={t('locale') === 'es-ES' ? "footer-language-button active" : "footer-language-button"} onClick={() => changeLanguage('es-ES')}>
                                    <span>ES</span>
                                </div>
                                <div className={t('locale') === 'ar-AR' ? "footer-language-button active" : "footer-language-button"} onClick={() => changeLanguage('ar-AR')}>
                                    <span>AR</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

let mapStateToProps = (state) => {
    return {
        channelList: state.home.channelList,
        categoriesList: state.home.categoriesList
    }
}

export default connect(mapStateToProps, {

})(Home);

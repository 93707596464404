import React, { useEffect, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Home from './scenes/home';
import NotFound from './scenes/404';

const App = ({
    store
}) => {

    const telegram = window.Telegram.WebApp;
    const pageRef = useRef(null);

    useEffect(() => {
        telegram.expand();
        telegram.HapticFeedback.impactOccurred('light');
        telegram.setHeaderColor(telegram.themeParams.section_bg_color);
    }, []);
    
    return (
        <div className="main" ref={pageRef}>
            <Routes>
                <Route path="/" element={ <Home store={store} pageRef={pageRef} /> } />
                <Route path="*" element={ <NotFound store={store} />} />
            </Routes>
        </div>
    );
}

let mapStateToProps = (state) => {
    return {
        // nothing :(
    }
}

export default connect(mapStateToProps, {
    
})(App);

import Logo from '../../assets/images/app/logo.svg'
import { useTranslation } from 'react-i18next';

const Header = ({
    searchText,
    setSearchText
}) => {

    const { t, i18n } = useTranslation();

    const handleSearchChange = e => {
        setSearchText(e.target.value);       
    };

    return (
        <div className="header">
            <div className="mid">
                <div className="header-items">
                    <a href="/" className="header-logo">
                        <div className="logo">
                            <img src={Logo} />
                        </div>
                        <div className="logo-name">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 811.11 81.5">
                                <path d="M35.99,63.73h-15.16L0,1.68H13.29l15.16,47.69L43.52,1.68h13.38l-20.92,62.04Z"/>
                                <path d="M100.07,46.27h-33.42c1.48,5.55,5.64,8.33,12.5,8.33,4.37,0,7.68-1.48,9.93-4.43l9.22,5.32c-4.37,6.32-10.81,9.48-19.32,9.48-7.33,0-13.21-2.22-17.64-6.65-4.43-4.43-6.65-10.02-6.65-16.75s2.19-12.17,6.56-16.66c4.31-4.49,9.93-6.74,16.84-6.74s11.91,2.25,16.04,6.74c4.25,4.49,6.38,10.05,6.38,16.66,0,1.24-.15,2.81-.44,4.7Zm-33.59-8.86h22.6c-.65-2.89-1.97-5.11-3.94-6.65-1.98-1.54-4.33-2.3-7.05-2.3-3.07,0-5.61,.78-7.62,2.35-2.01,1.57-3.34,3.77-3.99,6.6Z"/>
                                <path d="M119.92,19.41v7.62c1-2.78,2.72-4.89,5.14-6.34,2.42-1.45,5.2-2.17,8.33-2.17v12.76c-3.49-.47-6.62,.24-9.4,2.13-2.72,1.83-4.08,4.88-4.08,9.13v21.18h-11.43V19.41h11.43Z"/>
                                <path d="M145.71,14.09c-1.89,0-3.53-.69-4.92-2.08-1.39-1.39-2.08-3.03-2.08-4.92s.69-3.55,2.08-4.96c1.39-1.42,3.03-2.13,4.92-2.13s3.62,.71,5.01,2.13c1.39,1.42,2.08,3.07,2.08,4.96s-.7,3.53-2.08,4.92c-1.39,1.39-3.06,2.08-5.01,2.08Zm5.76,49.64h-11.43V19.41h11.43V63.73Z"/>
                                <path d="M185.77,.44V11.43c-6.26-.41-9.4,2.1-9.4,7.53v.44h9.4v10.99h-9.4V63.73h-11.43V30.4h-6.38v-10.99h6.38v-.44c0-6.32,1.76-11.09,5.27-14.31,3.52-3.22,8.7-4.62,15.56-4.21Z"/>
                                <path d="M198.83,14.09c-1.89,0-3.53-.69-4.92-2.08-1.39-1.39-2.08-3.03-2.08-4.92s.69-3.55,2.08-4.96c1.39-1.42,3.03-2.13,4.92-2.13s3.62,.71,5.01,2.13c1.39,1.42,2.08,3.07,2.08,4.96s-.7,3.53-2.08,4.92c-1.39,1.39-3.06,2.08-5.01,2.08Zm5.76,49.64h-11.43V19.41h11.43V63.73Z"/>
                                <path d="M258.13,46.27h-33.42c1.48,5.55,5.64,8.33,12.5,8.33,4.37,0,7.68-1.48,9.93-4.43l9.22,5.32c-4.37,6.32-10.81,9.48-19.32,9.48-7.33,0-13.21-2.22-17.64-6.65-4.43-4.43-6.65-10.02-6.65-16.75s2.19-12.17,6.56-16.66c4.31-4.49,9.93-6.74,16.84-6.74s11.91,2.25,16.04,6.74c4.25,4.49,6.38,10.05,6.38,16.66,0,1.24-.15,2.81-.44,4.7Zm-33.59-8.86h22.6c-.65-2.89-1.97-5.11-3.94-6.65-1.98-1.54-4.33-2.3-7.05-2.3-3.07,0-5.61,.78-7.62,2.35-2.01,1.57-3.34,3.77-3.99,6.6Z"/>
                                <path d="M299.79,24.64V1.68h11.43V63.73h-11.43v-5.23c-3.37,4.31-8.15,6.47-14.36,6.47s-11.08-2.25-15.33-6.74c-4.2-4.55-6.29-10.1-6.29-16.66s2.1-12.02,6.29-16.57c4.25-4.55,9.36-6.83,15.33-6.83s10.99,2.16,14.36,6.47Zm-21.1,25.97c2.42,2.3,5.38,3.46,8.86,3.46s6.47-1.15,8.77-3.46c2.3-2.36,3.46-5.38,3.46-9.04s-1.15-6.65-3.46-8.95c-2.3-2.36-5.23-3.55-8.77-3.55s-6.5,1.18-8.86,3.55-3.46,5.29-3.46,8.95,1.15,6.68,3.46,9.04Z"/>
                                <path d="M373.79,64.97c-9.28,0-16.99-3.1-23.13-9.31-6.09-6.14-9.13-13.8-9.13-22.96s3.04-16.87,9.13-22.96c6.15-6.2,13.86-9.31,23.13-9.31,5.61,0,10.8,1.32,15.56,3.94,4.76,2.63,8.46,6.19,11.12,10.68l-10.55,6.12c-1.54-2.78-3.72-4.95-6.56-6.51-2.84-1.56-6.03-2.35-9.57-2.35-6.03,0-10.9,1.89-14.62,5.67-3.66,3.84-5.5,8.75-5.5,14.71s1.83,10.78,5.5,14.62c3.72,3.78,8.6,5.67,14.62,5.67,3.55,0,6.75-.78,9.62-2.35,2.87-1.57,5.04-3.71,6.51-6.43l10.55,6.12c-2.66,4.49-6.37,8.05-11.12,10.68-4.76,2.63-9.94,3.94-15.56,3.94Z"/>
                                <path d="M445.5,58.23c-4.49,4.49-10.05,6.74-16.66,6.74s-12.17-2.25-16.66-6.74c-4.49-4.49-6.74-10.04-6.74-16.66s2.25-12.08,6.74-16.57c4.55-4.55,10.1-6.83,16.66-6.83s12.11,2.28,16.66,6.83c4.55,4.55,6.83,10.08,6.83,16.57s-2.28,12.11-6.83,16.66Zm-25.26-7.89c2.3,2.3,5.17,3.46,8.6,3.46s6.29-1.15,8.6-3.46c2.3-2.3,3.46-5.23,3.46-8.77s-1.15-6.47-3.46-8.77c-2.3-2.3-5.17-3.46-8.6-3.46s-6.29,1.15-8.6,3.46c-2.25,2.36-3.37,5.29-3.37,8.77s1.12,6.41,3.37,8.77Z"/>
                                <path d="M509.4,18.17c5.08,0,9.14,1.66,12.19,4.96,3.04,3.31,4.56,7.74,4.56,13.29v27.3h-11.43v-26.5c0-2.66-.65-4.73-1.95-6.2-1.3-1.48-3.13-2.22-5.5-2.22-2.6,0-4.62,.86-6.07,2.57-1.45,1.71-2.17,4.2-2.17,7.45v24.91h-11.43v-26.5c0-2.66-.65-4.73-1.95-6.2-1.3-1.48-3.13-2.22-5.5-2.22-2.54,0-4.57,.87-6.07,2.61-1.51,1.74-2.26,4.21-2.26,7.4v24.91h-11.43V19.41h11.43v4.7c2.66-3.96,6.77-5.94,12.32-5.94s9.51,2.13,12.05,6.38c2.89-4.25,7.3-6.38,13.21-6.38Z"/>
                                <path d="M584.92,18.17c5.08,0,9.14,1.66,12.19,4.96,3.04,3.31,4.56,7.74,4.56,13.29v27.3h-11.43v-26.5c0-2.66-.65-4.73-1.95-6.2-1.3-1.48-3.13-2.22-5.5-2.22-2.6,0-4.62,.86-6.07,2.57-1.45,1.71-2.17,4.2-2.17,7.45v24.91h-11.43v-26.5c0-2.66-.65-4.73-1.95-6.2-1.3-1.48-3.13-2.22-5.5-2.22-2.54,0-4.57,.87-6.07,2.61-1.51,1.74-2.26,4.21-2.26,7.4v24.91h-11.43V19.41h11.43v4.7c2.66-3.96,6.77-5.94,12.32-5.94s9.51,2.13,12.05,6.38c2.89-4.25,7.3-6.38,13.21-6.38Z"/>
                                <path d="M640.67,43.34V19.41h11.43V63.73h-11.43v-4.96c-2.72,4.14-7.12,6.21-13.21,6.21-4.85,0-8.86-1.62-12.05-4.88-3.13-3.31-4.7-7.8-4.7-13.47V19.41h11.43v25.79c0,2.9,.78,5.14,2.35,6.74,1.57,1.6,3.71,2.39,6.43,2.39,3.01,0,5.39-.93,7.14-2.79,1.74-1.86,2.61-4.59,2.61-8.2Z"/>
                                <path d="M687.2,18.17c4.9,0,8.89,1.63,11.96,4.88,3.19,3.31,4.79,7.8,4.79,13.47v27.21h-11.43v-25.79c0-2.89-.78-5.14-2.35-6.74-1.57-1.6-3.71-2.39-6.43-2.39-3.01,0-5.39,.93-7.13,2.79-1.74,1.86-2.62,4.6-2.62,8.2v23.93h-11.43V19.41h11.43v4.96c2.72-4.14,7.12-6.2,13.21-6.2Z"/>
                                <path d="M719.38,14.09c-1.89,0-3.53-.69-4.92-2.08-1.39-1.39-2.08-3.03-2.08-4.92s.69-3.55,2.08-4.96c1.39-1.42,3.03-2.13,4.92-2.13s3.62,.71,5.01,2.13c1.39,1.42,2.08,3.07,2.08,4.96s-.7,3.53-2.08,4.92c-1.39,1.39-3.06,2.08-5.01,2.08Zm5.76,49.64h-11.43V19.41h11.43V63.73Z"/>
                                <path d="M760.95,19.41v10.99h-10.02v18.44c0,1.54,.38,2.66,1.15,3.37,.77,.71,1.89,1.11,3.37,1.2,1.48,.09,3.31,.07,5.5-.04v10.37c-7.74,.89-13.25,.16-16.53-2.17-3.28-2.33-4.92-6.57-4.92-12.72V30.4h-7.71v-10.99h7.71V10.46l11.43-3.46v12.41h10.02Z"/>
                                <path d="M789.04,49.19l9.84-29.78h12.23l-16.13,44.32c-2.3,6.38-5.36,11-9.17,13.87-3.81,2.87-8.55,4.15-14.23,3.86v-10.64c3.01,.06,5.44-.59,7.27-1.95,1.83-1.36,3.28-3.54,4.34-6.56l-18.17-42.9h12.5l11.52,29.78Z"/>
                            </svg>
                        </div>
                    </a>
                    <div className="header-search">
                        <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M3.87109 10.4463C3.87109 9.5332 4.04264 8.67822 4.38574 7.88135C4.72884 7.07894 5.20475 6.37337 5.81348 5.76465C6.4222 5.15592 7.125 4.68001 7.92188 4.33691C8.72428 3.99382 9.58203 3.82227 10.4951 3.82227C11.4082 3.82227 12.2632 3.99382 13.0601 4.33691C13.8625 4.68001 14.568 5.15592 15.1768 5.76465C15.7855 6.37337 16.2614 7.07894 16.6045 7.88135C16.9476 8.67822 17.1191 9.5332 17.1191 10.4463C17.1191 11.2044 16.9974 11.9238 16.7539 12.6045C16.516 13.2852 16.1839 13.9022 15.7578 14.4556L19.8169 18.5396C19.9054 18.6281 19.9718 18.7305 20.0161 18.8467C20.0659 18.9629 20.0908 19.0874 20.0908 19.2202C20.0908 19.4028 20.0493 19.5688 19.9663 19.7183C19.8888 19.8677 19.7782 19.9839 19.6343 20.0669C19.4904 20.1554 19.3244 20.1997 19.1362 20.1997C19.0034 20.1997 18.8761 20.1748 18.7544 20.125C18.6382 20.0807 18.5303 20.0116 18.4307 19.9175L14.3467 15.8252C13.8044 16.2126 13.2067 16.5169 12.5537 16.7383C11.9007 16.9596 11.2145 17.0703 10.4951 17.0703C9.58203 17.0703 8.72428 16.8988 7.92188 16.5557C7.125 16.2126 6.4222 15.7367 5.81348 15.1279C5.20475 14.5192 4.72884 13.8164 4.38574 13.0195C4.04264 12.2171 3.87109 11.3594 3.87109 10.4463ZM5.29053 10.4463C5.29053 11.1657 5.42334 11.8408 5.68896 12.4717C5.96012 13.097 6.33366 13.6476 6.80957 14.1235C7.29102 14.5994 7.8444 14.973 8.46973 15.2441C9.10059 15.5153 9.77572 15.6509 10.4951 15.6509C11.2145 15.6509 11.8869 15.5153 12.5122 15.2441C13.1431 14.973 13.6965 14.5994 14.1724 14.1235C14.6483 13.6476 15.0218 13.097 15.293 12.4717C15.5641 11.8408 15.6997 11.1657 15.6997 10.4463C15.6997 9.72689 15.5641 9.05452 15.293 8.4292C15.0218 7.79834 14.6483 7.24495 14.1724 6.76904C13.6965 6.2876 13.1431 5.91406 12.5122 5.64844C11.8869 5.37728 11.2145 5.2417 10.4951 5.2417C9.77572 5.2417 9.10059 5.37728 8.46973 5.64844C7.8444 5.91406 7.29102 6.2876 6.80957 6.76904C6.33366 7.24495 5.96012 7.79834 5.68896 8.4292C5.42334 9.05452 5.29053 9.72689 5.29053 10.4463Z" fill="currentColor"></path>
                        </svg>
                        <input type="search" className="input" placeholder={t('search')} value={searchText} onChange={handleSearchChange} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;